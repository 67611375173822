// ============
//  RICH TEXT
// ============

.RichText-underline {
  display: inline-block;
  padding: 0 2px;
  margin: 0 -2px;
  box-shadow:
    0 -2px 0 0 $white inset,
    0 -10px 0 0 $teal inset;

  @media #{$laptop} {
    padding: 0 4px;
    margin: 0 -4px;
    box-shadow:
      0 -3px 0 0 $white inset,
      0 -14px 0 0 $teal inset;
  }

  @media #{$desktop} {
    padding: 0 6px;
    margin: 0 -6px;
    box-shadow:
      0 -6px 0 0 $white inset,
      0 -18px 0 0 $teal inset;
  }
}

// ===============
// MODIFIERS
// ===============
.RichText-underline--drawn {
  position: relative;
  box-shadow: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-image: url("/assets/images/interface/underline.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 10px;
    width: 100%;
    bottom: 0;
    z-index: -1;

    @media #{$laptop} {
      height: 15px;
    }
    @media #{$desktop} {
      height: 18px;
    }
    @media #{$widescreen} {
      height: 20px;
    }
  }
}