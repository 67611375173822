// ========================
// LINK POST
// ========================
// @note: Gets displayed as a tile on the Ideas Factory page
// ========================
.LinkPost {
  border: solid 1px $border;
  background-color: $gentler-rhino;
  border-radius: 4px;
  backface-visibility: hidden;
  padding: $v $h;
  margin-bottom: $v;

  &:hover {
    color: $flamboyant-flamingo;
  }
}

// ========================
// ELEMENTS
// ========================
.LinkPost-title {
  @extend %h4;
  margin-bottom: $v;
  margin-top: 0;
  color: $primary;
  transition: color 0.3s ease-in-out;
  line-height: 1.25;

  .LinkPost:hover & {
    color: $flamboyant-flamingo;
  }
}

.LinkPost-description {
  @include font(16px);
  margin-top: 0;
  margin-bottom: 0;
}
