// ============================
// US OTHER AWARDS - US PAGE
// ============================

.UsOtherAwards {
  position: relative;
}

// ========================
// ELEMENTS
// ========================

.UsOtherAwards-container {
  @media #{$laptop} {
    display: flex;
  }
}

.UsOtherAwards-content {
  @media #{$tablet} {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100%;
    margin: {
      top: 0;
      right: auto;
      bottom: 0;
      left: auto;
    }
  }

  @media #{$laptop} {
    display: block;
  }
}

.UsOtherAwards-content-items {
  margin-top: $v;

  @media #{$tablet} {
    padding-right: $v;
  }

  @media #{$laptop} {
    margin-top: 0;
    margin-bottom: $v;
  }
}

.UsOtherAwards-content-item {
  @extend %small;
  list-style: none;
}

.UsOtherAwards-showMore {
  text-align: center;
}

.UsOtherAwards-showMore-toggle {
  @extend %font-bold;
  background-color: $white;
  font-size: rem(12px);
  z-index: 1;

  .LinkArrow {
    display: none;
  }

  span {
    padding: 0;
  }
}

// ====================
// UI STATE
// ====================
.UsOtherAwards-gradient.is-closed {
  position: absolute;
  height: 150px;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(254, 254, 254, 0), $white);
}
