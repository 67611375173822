// ============
// OPEN POSITION
// ============
// Styles for the open positions list component


// Open positions – the container for individual position postings

// .OpenPositions {}

// ========================
// ELEMENTS
// ========================

.OpenPositions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

// Open position – the individual position postings

.OpenPosition:not(:last-child) {
  margin-bottom: $v;
}

.OpenPosition-title {
  margin: 0;
  position: relative;
  cursor: pointer;
  display: block;
  padding-right: $v;

  @media #{$tablet} {
    padding-right: 0;
  }

  .OpenPosition-title-button {
    @extend %font-bold;
    @extend %p;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    color: $midnight;
  }

  .icon {
    position: absolute;
    top: 4px;
    transition: transform 0.2s ease-in-out;
    right: 0;

    @media #{$tablet} {
      right: -$v;
    }
  }
}

.OpenPosition-header-button {
  border: none;
  outline: none;
}

.OpenPosition-location {
  margin: 0;
}

.OpenPosition-jobDescription {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.6s ease-in-out, opacity 0.3s ease-in-out 0.1s;
}

.OpenPosition-jobDescription--isActive {
  max-height: 500px;
  opacity: 1;
}

.OpenPosition-title--isActive .icon {
  transform: rotate(180deg);
}
