// ========================
// TWO COLUMN TEXT
// ========================
// @note: goes here
// ========================
// .TwoColumnText {}

// ========================
// ELEMENTS
// ========================
.TwoColumnText-split {
  padding-left: $h / 3;
  padding-right: $h / 3;
}
// .TwoColumnText-anotherPart {}

// ========================
// MODIFIERS
// ========================
// .TwoColumnText--modifier {}

// ========================
// UI STATES
// ========================
// .TwoColumnText.is-active {}
