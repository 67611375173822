// ========================
// Split
// ========================
// @note: Split-like module that appears on the home page
// ========================
.Split {
  margin-bottom: rem(48px);

  @media #{$laptop} {
    margin-bottom: rem(66px);
  }
  @media #{$desktop} {
    margin-bottom: rem(80px);
  }
  @media #{$widescreen} {
    margin-bottom: rem(88px);
  }
}