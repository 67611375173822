// ==============================================
// Generated by Animista on 2019-1-24 22:17:14
// w: http://animista.net, t: @cssanimista
// ==============================================


// ==============================================
// animation jello-horizontal
// ==============================================
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}


// ==============================================
// animation placeholder grey pulse
// ==============================================
@keyframes placeholder-pulse {
  0%, 100% {
    background-color: $gentle-rhino;
  }

  50% {
    background-color: rgba($gentle-rhino, 0.4);
  }
}

@keyframes placeholder-pulse-opacity {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}
