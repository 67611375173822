// ========================================
// CASE STUDY SPLIT:
// LFT01, LFT02, LFT04, RGH01, RGH02, RGH04
// ========================================

.CaseStudy-split-clientName {
  color: $secondary;
}

// Explicitly setting SVG heights for IE
.CaseStudy-split .Animator {
  svg {
    display: block;
    height: 100%;
    min-height: rem(280px);

    @media #{$tablet} {
      min-height: rem(420px);
    }

    @media #{$laptop} {
      min-height: rem(529px);
    }
  }
}
