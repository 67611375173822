.u-debug-grid {
  .Grid {
    @include debug-grid($grid-gutter-sm, 12);

    @media #{$laptop} {
      @include debug-grid($grid-gutter-lg, 12);
    }
  }

  .Grid--noGutter {
    @include debug-grid(1px, 12);
  }
}
