// ========================
// PARTNERS
// ========================

.Partners {
  background-color: $primary;
  color: $white;
  padding-top: $v * 2;
  padding-bottom: $v * 0.75;

  @media #{$tablet} {
    padding-top: $v * 2.25;
  }

  @media #{$laptop} {
    padding-top: $v * 3.25;
    padding-bottom: $v * 2;
  }
}

// ========================
// ELEMENTS
// ========================

.Partners-inner {
  @media #{$tablet} {
    padding-left: $h * 2.25;
    padding-right: $h * 2.25;
  }

  @media #{$laptop} {
    padding-left: $h * 3.25;
    padding-right: $h * 2;
  }

  @media #{$desktop} {
    padding-left: $h * 4;
    padding-right: $h * 2;
  }
}

.Partners-title {
  margin-top: 0;
  padding-right: $v * 2;

  @media #{$tablet} {
    width: 60%;
    padding: 0;
    margin-bottom: $v * 2;
  }

  @media #{$laptop} {
    margin-bottom: $v * 3;
  }
}

.Partners-list {
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  width: 100%; // for IE
  padding: 0;
  margin: {
    bottom: 0;
  }

  @media #{$tablet} {
    display: flex;
  }
}

.Partners-list-logo {
  max-height: rem(64px); // for IE
  margin-bottom: $v;
  padding: {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  img {
    width: 150px;
    height: 100%;
  }
}

.Partners-wrapper {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: $v * 6;
    position: absolute;
    bottom: 0;
    opacity: 1;
    pointer-events: none;
    transition: opacity $timing linear;
    background-image:
      linear-gradient(
        to bottom,
        hsla(242.76, 97.75%, 17.45%, 0) 0%,
        hsla(242.76, 97.75%, 17.45%, 0.15625) 33.34%,
        hsla(242.76, 97.75%, 17.45%, 0.5) 65.75%,
        hsla(242.76, 97.75%, 17.45%, 0.84375) 90.28%,
        hsl(242.76, 97.75%, 17.45%) 100%
      );
  }

  &.is-open::after {
    opacity: 0;
  }
}

.Partners-toggle {
  @extend %small;
  @extend %font-bold;
  appearance: none;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $white;
  height: rem(68px);
  width: 100%;
  outline: none;
  transition: color $timing linear;

  &.focus-visible {
    color: $secondary;
  }

  @media #{$not-touch} {
    &:hover {
      color: $secondary;
    }
  }
}
