// ==================
// CASE STUDY CAPSULE
// ==================

// Height of the image at different breakpoints.
// A little magic, hence using a map+variables to ensure consistency
$skewImageHeights: (
  $tablet: 330px,
  $laptop: 443px,
  $desktop: 580px,
  $widescreen: 720px
);

.CaseStudyCapsule {
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  text-align: left;
  color: $text;
  position: relative;
}

// ========================
// ELEMENTS
// ========================

.CaseStudyCapsule-imageContainer {
  position: relative;
  height: 0;

  // Force the image to have the correct aspect-ratio on small screens
  // Needed for IE, since it doesn't do nice automatic SVG sizing
  // See https://css-tricks.com/scale-svg/
  padding-bottom: 200%;

  @media #{$tablet} {
    height: auto;
    padding-bottom: 0;
  }

  @each $mediaQuery, $height in $skewImageHeights {
    @media #{$mediaQuery} {
      height: $height;
    }
  }
}

.CaseStudyCapsule-graphic,
.CaseStudyCapsule-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.CaseStudyCapsule-graphic {
  position: absolute;
  z-index: 2;
}

.CaseStudyCapsule-graphic-background,
.CaseStudyCapsule-graphic-foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.CaseStudyCapsule-graphic-foreground {
  opacity: 1;
  transition: opacity 0.2s linear;
  will-change: opacity;
}

.CaseStudyCapsule-content {
  padding-top: $v / 2;
  padding-left: $h / 2;
  padding-right: $h / 2;
  padding-bottom: $v;
  position: absolute;
  top: 14%;
  z-index: 3;
  margin: auto;
  max-width: rem(230px);

  @media #{$desktop} {
    padding-left: 0;
  }

  @media #{$widescreen} {
    top: 16%;
    max-width: rem(310px);
  }
}

.CaseStudyCapsule-content-title,
.CaseStudyCapsule-content-subtitle {
  line-height: 1.25;
  color: $white;
}

.CaseStudyCapsule-content-title {
  @extend %font-medium;
  margin-top: 0;
  margin-bottom: $v / 3;
  @include font(16px);

  @media #{$laptop} {
    @include font(24px);
  }

  @media #{$desktop} {
    @include font(32px);
  }
}

.CaseStudyCapsule-content-subtitle {
  @media #{$mobile-only} {
    display: none;
  }

  @extend %font-bold;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 0, 12);
  transition:
    transform 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.2s linear;
  will-change: transform, opacity;
  @include font(20px);

  @media #{$laptop} {
    @include font(24px);
  }

  @media #{$desktop} {
    @include font(32px);
  }
}


// ==========================
// STATES
// ==========================
// .CaseStudyCapsule.is-ready {
//   animation-play-state: paused;

//   .CaseStudyCapsule-graphic,
//   .CaseStudyCapsule-content {
//     opacity: 1;
//   }
// }

.CaseStudyCapsule:hover,
.CaseStudyCapsule:active,
.CaseStudyCapsule:focus {
  color: $text;
}

.CaseStudyCapsule:hover {
  &::after {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .CaseStudyCapsule-content-subtitle {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .CaseStudyCapsule-graphic-foreground {
    @media #{$tablet} {
      opacity: 0.25;
    }
  }
}

// ==========================
// MODIFIERS
// ==========================

// === Dark Gradient, Light Text
.CaseStudyCapsule--light {
  .CaseStudyCapsule-content {
    color: $white;
  }

  &:hover,
  &:active,
  &:focus {
    .CaseStudyCapsule-content {
      color: $white;
    }
  }
}

// The Tilt.js library has some major jank/stuttering in IE and Edge, so we force a more simple/static transition to be used
.Tilt {
  height: 100%;

  @media #{$ie} {
    transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 1s !important;

    &:hover {
      transform: scale(1.08) !important;
    }
  }

  // Same thing, but for Edge
  @supports (-ms-ime-align:auto) {
    transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 1s !important;

    &:hover {
      transform: scale(1.08) !important;
    }
  }
}
