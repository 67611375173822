// ============
// $MENU
// ============
// This is the main menu, situated in the overlay

$menu-social-height: 80px;

.Menu {
  position: fixed !important;
  color: $white;
  width: 100vw;
  height: 100vh;
  z-index: $z-menu;
}

// ========================
// ELEMENTS
// ========================

// === OVERLAY LAYERS FOR ANIMATION
.Menu-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.Menu-overlay-layer {
  position: fixed;
  height: 140%;
  width: 100%;
  margin-top: -20%;

  @media #{$tablet} {
    width: 140%;
    margin-top: 0;
    margin-left: -20%;
  }

  &--1 {
    background: $cyan;
  }
  &--2 {
    background: $flamingo;
  }
  &--3 {
    background: $midnight;
  }
}

// === INNER CONTENT
.Menu-content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.Menu-headerLogo {
  @extend %header-sizing;
  // animation: 0.5s showLogo;
  // visibility: hidden;
  // animation-fill-mode: forwards;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;

  svg {
    width: rem(120px) !important;

    @media #{$tablet} {
      width: rem(180px) !important;
    }
  }
}

@keyframes showLogo {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.Menu-content-controls {
  @extend %header-sizing;
  z-index: 1000;
  background-color: $midnight;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Menu-content-routes {
  margin: auto;
  padding-top: $v * 2;
  padding-bottom: $v * 2;

  @media #{$tablet} {
    padding-left: 84px;
    padding-top: $v * 2;
    padding-top: calc(
      #{$v} + #{$menu-social-height}
    ); // To keep the menu items vertical centered by balancing the social icons
  }

  @media #{$laptop} {
    padding-left: 154px;
  }

  .SocialLinks {
    margin-top: rem(40px);

    @media #{$tablet} {
      margin-top: rem(96px);
      justify-content: flex-start;
    }

    @media #{$laptop} {
      margin-top: rem(109px);
    }

    @media #{$desktop} {
      margin-top: rem(132px);
    }
  }
}

.Menu-social {
  .SocialLinks-item {
    .icon {
      font-size: 30px;

      @media #{$tablet} {
        font-size: 48px;
      }
    }
  }

  // margin-bottom: $v;
  flex-shrink: 0;

  icon {
    width: 30px;
  }

  @media #{$tablet} {
    margin-bottom: 0;
    height: $menu-social-height;
  }
}

.Menu-list {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: auto;
  column-gap: 10%;
  list-style-type: none;
  padding: 0;
  margin: {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media #{$laptop} {
    grid-template-columns: 1fr 1fr;

    margin-left: 0;
    padding-left: 0;
  }
}

.Menu-list-item {
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 8px;

    @media #{$tablet} {
      margin-bottom: 16px;
    }

    @media #{$desktop} {
      margin-bottom: 32px;
    }
  }

  a {
    // Custom font size for Menu items – perhaps should be Display?
    @include font(24px, 36px);
    @extend %font-regular;
    text-decoration: none;
    color: $white;
    display: inline-block;
    position: relative;

    @media #{$tablet} {
      @include font(48px, 64px);
    }

    @media #{$laptop} {
    }
    @media #{$desktop} {
      @include font(56px, 64px);
    }
    @media #{$widescreen} {
      @include font(62px, 62px);
    }
  }

  &.is-active a {
    @extend %font-bold;
  }
}

.Menu-list-item-title {
  transition: color $timing linear;

  @media #{$tablet} {
    padding-right: rem(84px);
  }

  .LinkArrow {
    display: none;

    @media #{$tablet} {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: rem(-4px);
    }
  }

  .LinkArrow-body,
  .LinkArrow-head {
    @media #{$tablet} {
      stroke: $secondary;
    }
  }
}

// ===========
// HOVER STATE
// ===========
.Menu-list-item:hover {
  @extend %arrow-hover;
  color: $primary;

  a {
    color: $flamboyant-flamingo;
    transition: color 0.3s ease(in-cubic);
  }

  .Menu-list-item-title {
    color: $secondary;
  }
}

.Menu-graphic {
  flex-shrink: 0;
  width: rem(225px);
  height: rem(225px);
  display: none;

  @media #{$tablet} {
    display: block;
  }

  @media #{$tablet} {
    width: rem(270px);
    height: rem(270px);
  }

  @media #{$laptop} {
    width: rem(364px);
    height: rem(364px);
  }

  @media #{$desktop} {
    width: rem(446px);
    height: rem(446px);
  }
}

.Menu-graphic-animation {
  height: 100%;
  width: 100%;
}

.Menu-listHeading {
  font-family: "Gilroy Regular";
  opacity: 0.5;
  margin-bottom: 16px;

  @media #{$desktop} {
    @include font(24px, 64px);
  }
}
