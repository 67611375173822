// ======================================
// CASE STUDY POSTER: POS01-01a POS01-01b
// ======================================

$posterImageHeights: (
  $tablet: 345px,
  $laptop: 460px,
  $desktop: 514px
);

// .CaseStudy-poster {}

// ========================
// ELEMENTS
// ========================

.CaseStudy-poster-image {
  // Target the image to ensure it's the correct width and height if the CMS
  // provides an incorrectly sized image.
  .ImageWrapper .ImageWrapper-img {
    background-position: 50% 50%;
    background-size: contain;
  }
}

// Explicitly setting SVG heights for IE
.CaseStudy-poster .Animator svg {
  min-height: 300px;
}

// Modifier CaseStudy-poster--hasAwards also targets this class
.CaseStudy-poster-caption {
  width: 100%;
}

.CaseStudy-poster-awards {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.CaseStudy-poster-awards-item {
  background-color: $white;
  margin-bottom: $v / 2;
  margin-right: 0;

  @media #{$tablet} {
    margin-right: $h / 2;
  }

  @media #{$laptop} {
    margin-right: $h / 2;
  }

  @media #{$desktop} {
    margin-bottom: $v;
    margin-right: $h;
  }
}

// ========================
// MODIFIERS
// ========================

.CaseStudy-poster--hasText {
  .CaseStudy-poster-awards {
    @media #{$tablet} {
      flex-direction: row;
    }
  }

  .CaseStudy-poster-awards-item {
    @media #{$laptop} {
      margin-right: $v / 2;
    }

    @media #{$desktop} {
      margin-right: $v;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
}

// POS01-01c
.CaseStudy-poster--leftCaption {
  .CaseStudy-poster-captionContainer {
    position: absolute;
    top: 0;
    left: 0;

    @media #{$tablet} { top: 50%; }
  }
}
