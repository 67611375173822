html {
  @extend %font-regular !optional;
  @include font($base-font-size);
  line-height: $base-line-height;
  color: $text;
  // Better Font Rendering
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Text highlight color
// NOTE: must be specified individually
::selection { background: $highlight; color: $white; }
::-moz-selection { background: $highlight; color: $white; }

a {
  color: $link;
  text-decoration: underline;
  @include hover () {
    color: $active;
  }
}

em, i { font-style: italic; }
strong,b { @extend %font-bold !optional; }

ul {
  list-style-type: disc;
  padding-left: $h;
  margin: $v 0 $v -6px;
}

ol {
  list-style: decimal;
  padding-left: $h;
  margin: $v 0;
}


ol, .ol {
  list-style: none;
  counter-reset: li-index;
  padding-left: $h;

  li {
    counter-increment: li-index;
    position: relative;

    &::before {
      @extend %font-bold;
      content: counter(li-index) ". ";
      display: inline-block;
      text-align: right;
      color: inherit;
      width: $h;
      margin-left: -($h * 1.5);
      margin-right: $h / 2;
    }
  }
}

// // Custom Bullets
// ul, .ul {
//   list-style-type: none;
//   padding-left: $h / 2;

//   li::before {
//     @extend %font-bold;
//     content: "\2022"; // CSS unicode for a bullet
//     display: inline-block;
//     color: $secondary;
//     width: $h / 2;
//     margin-left: -($h / 2);
//   }
// }

li {
  @extend %p;

  &:not(:last-child) {
    margin-bottom: $v / 2;
  }
}

sub,
sup {
  @extend %small !optional;
  line-height: 0;
}

hr {
  border: none;
  margin: 0;
  background-color: $border;
  height: 1px;
}

// Phone numbers ============
// Avoid wrapping phone numbers onto multiple lines
[href="tel"] { white-space: nowrap; }

