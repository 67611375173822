// ============
// $POSITION
// ============

@mixin position($namespace: "") {
  .u-#{$namespace}posRelative { position: relative !important; }
  .u-#{$namespace}posAbsolute { position: absolute !important; }
  .u-#{$namespace}posStatic { position: static !important; }
  .u-#{$namespace}posFixed { position: fixed !important; }
  .u-#{$namespace}posSticky { position: sticky !important; }
}

@include position();
// @media #{$mobile-only} { @include position("sm-"); }
// @media #{$tablet} { @include position("md-"); }
// @media #{$laptop} { @include position("lg-"); }
// @media #{$desktop} { @include position("xl-"); }
// @media #{$widescreen} { @include position("xx-"); }
