// ========================
// IDEAS FACTORY FEATURE
// ========================
// @note: Displays on the home page
// ========================
.IdeasFactoryFeature {
  transform: translateZ(-1000px); // Safari does some weird artifacting on the tilt animation sifter #26493

  > div {
    overflow: hidden; // Hide the excess skeleton grid
    position: relative;
    background-color: $gentler-rhino;

    padding: {
      left: $h;
      right: $h;
    }

    margin-bottom: $v * 2;

    @media #{$tablet} {
      margin-bottom: $v * 3;
    }

    @media #{$laptop} {
      padding: {
        left: $h;
        right: $h;
      }
    }

    > a {
      text-decoration: none;
      display: flex;
      flex-direction: column;

      @media #{$tablet} {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .Button {
    width: 100%;
    padding: 0 24px;

    @media #{$tablet} {
      margin-top: 8px;
      width: 227px;
    }
  }

  .SkeletonGrid {
    z-index: 1;
  }
}

.IdeasFactoryFeature-contentAnimationWrapper {
  @media #{$tablet} {
    display: grid;
    grid-template-columns: 1fr 0 50% 0;
    grid-template-areas: "content . animation .";
  }

  @media #{$desktop} {
    grid-template-columns: 1fr 5% 40% 2.5%;
  }
}

// ========================
// ELEMENTS
// ========================
.IdeasFactoryFeature-content {
  width: 100%;
  padding: 28px 0;
  z-index: 2;

  @media #{$laptop} {
    padding: 40px 0;
  }

  @media #{$desktop} {
    padding: 48px 0;
  }

  @media #{$widescreen} {
    padding-bottom: 0;

  }

  .RichText-underline--drawn {
    &::before {
      height: 15px;

      @media #{$laptop} {
        height: 20px;
      }
      @media #{$desktop} {
        height: 24px;
      }
    }
  }
}

.IdeasFactoryFeature-contentText {
  grid-area: content;
}

.IdeasFactoryFeature-content-title {
  @extend %h5;
  margin-bottom: 4px;

  color: $primary;
  margin-top: 0;
}

.IdeasFactoryFeature-content-subtitle {
  color: $primary;
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.29;

  @media #{$tablet} {
    line-height: 1.2;
  }
}

.IdeasFactoryFeature-animationArea {
  grid-area: animation;
}

.IdeasFactoryFeature-animation {
  z-index: 2;

  .Animator {
    margin-left: auto;
    margin-right: auto;

    @media #{$desktop} {

      svg {
      }
    }
  }
}

// ========================
// MODIFIERS
// ========================
// .--modifier {}

// ========================
// UI STATES
// ========================
// ..is-active {}
