// =============
// IMAGE WRAPPER
// =============

$blur-up-fade-duration: 0.4s;

.BlurUpLoader {
  width: 100%;
  height: 100%;
}

// ========================
// ELEMENTS
// ========================
.BlurUpLoader-fullImage {
  transition: opacity $blur-up-fade-duration;
  opacity: 0;
}

// =============
// MODIFIER
// =============


// =============
// STATE
// =============

.BlurUpLoader.is-ready {
  animation: none;
  background-color: transparent;

  .BlurUpLoader-fullImage {
    opacity: 1;
  }

  .BlurUpLoader-previewImage {
    opacity: 0;
    transition: opacity 0;
    transition-delay: $blur-up-fade-duration;
  }
}
