// =============
// SOCIAL LINKS
// =============
// These are the three components at the bottom of the footer and menu

.SocialLinks {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 0;
}

// ========================
// ELEMENTS
// ========================

.SocialLinks-item {
  flex-shrink: 0;
  width: rem(32px);
  height: rem(32px);

  @media #{$tablet} {
    width: rem(40px);
    height: rem(40px);
  }

  &:not(:first-child) {
    margin-left: rem(4px);
  }

  a, button {
    display: block;
    color: $primary;
    width: 100%;
    height: 100%;
    text-decoration: none;

    &:hover, &:active {
      color: $secondary !important; // react-share library does dumb shit
      animation: jello-horizontal 0.4s ease-out both;
    }
  }

  .icon {
    @include font(40px);

    @media #{$tablet} {
      @include font(40px);
    }
  }
}

// ========================
// MODIFIERS
// ========================

.SocialLinks--isDark {
  .SocialLinks-item a,
  .SocialLinks-item button { color: $white; }
}
