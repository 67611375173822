// ========================
// SKELETON TEXT
// ========================

.SkeletonText {
  @include loading-text(attr(data-text));
}

// ========================
// ELEMENTS
// ========================

.SkeletonRect {
  @extend %loading-pulse;
}

// ========================
// MODIFIERS
// ========================

.SkeletonText--light {
  @include loading-text(attr(data-text), rgba($white, 0.08));
}

.SkeletonRect--16x9 {
  @include fit-media(16, 9);
}
