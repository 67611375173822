// ========================
// IDEAS FACTORY ARTICLE
// ========================
// @note: goes here
// ========================
.Article {
  // a { // Too specific/not scoped
  //   color: $primary;
  //   text-decoration: none;
  // }

  .SocialLinks {
    .SocialLinks-item:first-of-type {
      width: 34px; // sifter #26163: The LinkedIn icon was being slightly cut off on iPhone 11
    }
  }
}

// ========================
// ELEMENTS
// ========================
.Article-hero {
  color: $white;
  background-color: $midnight;
  padding-top: $v;
  padding-bottom: $v * 1.5;

  .Author-name,
  .Author-role {
    color: $white;
  }
}

.Article-layout {
  padding-top: $v * 2;
  position: relative;
}

.Article-layout-tags {
  @media #{$tablet} {
    border-top: 1px solid $border-grey;
    padding-top: $v;
  }

  @media #{$desktop} {
    border-top: none;
    padding-top: 0;
  }
}

.Article-layout-share {
  margin-top: $v;
  margin-bottom: $v;

  @media #{$tablet} {
    margin-top: 0;

    border-top: 1px solid $border-grey;
    padding-top: $v;
  }

  @media #{$desktop} {
    margin-top: $v * 2;
    margin-bottom: 0;

    border-top: none;
    padding-top: 0;
  }
}

.Article-layout-cta {
  display: block;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $gentler-rhino;
  padding-top: $v;
  padding-left: $v;
  padding-right: $v;
  padding-bottom: $v / 2;

  .Link {
    @include font(16px);

    @media #{$desktop} {
      @include font(20px);
    }
  }
}

.Article-layout-cta-icon {
  margin: 0 auto;
}

.Article-layout-relatedPosts {
  .Link {
    height: 35px;
    margin-top: $v;

    @media #{$tablet} {
      margin-top: 0;
    }
    @media #{$laptop} {
      margin-top: $v;
    }
  }

  .More {
    margin-bottom: $v;

    @media #{$tablet} {
      margin-bottom: 0;
    }
    @media #{$laptop} {
      margin-bottom: $v;
    }
  }

  .MasonryGrid-column {
    margin-bottom: -$v / 2;

    @media #{$tablet} {
      margin-bottom: 0;
    }

    @media #{$laptop} {
      margin-bottom: -$v;
    }
  }
}

.Article-layout-relatedPosts-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media #{$tablet} {
    margin-bottom: $v / 2;
  }
  @media #{$laptop} {
    margin-bottom: 0;
  }
}

.Article-layout-cta-icon {
  background-image: url('/assets/images/interface/talk.svg');
  display: block;
  width: 65px;
  height: 55px;
}

.Article-layout-backToTop {
  border-top: 1px solid $border-grey;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @media #{$laptop} {
    padding-bottom: 80px;
  }
}

.Article-layout-backToTop-button {
  @include font(16px);
  height: 30px;
  color: $magic-grey;
  font-family: inherit;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;

  @media #{$desktop} {
    @include font(20px);
  }
}

.Article-layout-post {
  p b {
    color: $primary;
  }
}

.Article-layout-post-image {
  display: flex;
  background-color: $gentler-rhino;
  align-items: center;
  justify-content: center;
  margin-bottom: $v / 2;
}

// To prevent the image being stretched to the right on IE
.Article-layout-post-image-content {
  width: 100%;
}

.Article-layout-post-quote {
  margin: $v * 2.5 0 $v * 2.5;
  text-align: center;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  padding-top: $v * 1.5;
  padding-bottom: $v * 1.5;

  &:last-child {
    border-bottom: none;
  }
}

.Article-layout-post-quote-content {
  p {
    color: $primary;
  }
}

.Article-layout-post-quote-person {
  p {
    color: $primary;
  }
}

.Article-layout-post-quote-content * {
  @extend %h5;
}

.Article-layout-post-quote-icon {
  background-image: url('/assets/images/interface/quotemarks.svg');
  display: block;
  width: rem(64px);
  height: rem(64px);
  margin-left: auto;
  margin-right: auto;
}

// ========================
// MODIFIERS
// ========================
// .Article--modifier {}

// ========================
// UI STATES
// ========================
.Article-layout-backToTop-button:hover {
  color: $flamingo;
}
