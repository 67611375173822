// =============================
// ENERGY SECTOR HERO
// =============================

$imageHeight: 600px;
$heroHeightMobile: calc(100vh - #{$header-height-sm});
$heroHeightTablet: calc(100vh - #{$header-height-md});
$heroHeightLaptop: calc(100vh - #{$header-height-md});

$bottomHeight: 870px; // approx height of the bottom section - this controls when the bulb stops sticking

.EnergySectorHero {
  margin-bottom: $v * 4;
}

// ========================
// ELEMENTS
// ========================

.EnergySectorHero-section {
  width: 100%;
  clip-path: inset(0);
  display: flex;
  align-items: center;
}

// IMAGE RELATED STYLING ========

.EnergySectorHero-positionResetter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
}

.EnergySectorHero-stickyBack {
  margin-top: $heroHeightMobile;

  @media #{$tablet} {
    margin-top: $heroHeightTablet;
  }

  @media #{$laptop} {
    margin-top: $heroHeightLaptop;
  }
}

.EnergySectorHero-imageContainer {
  display: flex;
  justify-content: flex-end;
}

.EnergySectorHero-image {
  position: relative;
  height: $imageHeight;
  transform: translateX(42%); // the most magical of numbers

  > img {
    max-width: none;
    height: 100%;
    width: auto;
  }

  // The lightbulb wire. These values are very heavily tied to the positioning of things in the bulb image asset
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 70.25%;
    left: 50%;
    width: 2px;
    height: 100vh;
    transform: translateX(-100%);
    background: white;

    @media #{$desktop} {
      width: 3px;
    }
  }

  @media #{$tablet} {
    width: 65%;
    height: auto;
    top: calc(50vh + #{$header-height-md});
    transform: translateX(25%) translateY(-50%);

    > img {
      width: 100%;
      height: auto;
    }
  }

  @media #{$laptop} {
    top: calc(50vh + #{$header-height-md});
  }
}

.EnergySectorHero-image--back {
  // Only affect the positioning in non-sticky browsers (IE and Edge)
  top: ($bottomHeight / 2) + ($imageHeight / 4);
}

.EnergySectorHero-image--back::after {
  background: $midnight;
}

.EnergySectorHero-image--front {
  @media #{$mobile-only} {
    top: calc(80vh - #{($imageHeight / 2)});
  }
}

// Progressive enhancement for modern browsers - adding sticky/scrolly/clippy image things
// Note that we force a polyfill to be used for position:sticky in the JS, to avoid Safari bugs
@supports ((clip-path: inset(0)) and (position: sticky)) {
  .EnergySectorHero {
    position: relative;
  }

  .EnergySectorHero-positionResetter--front {
    position: fixed;
    z-index: -1;
    pointer-events: none;
  }

  .EnergySectorHero-stickyBack {
    position: sticky;
    top: 0;
    height: calc(
      (100vh + #{$bottomHeight}) / 2
    ); // makes the bulb stop sticking when it's half-way down the bottom height (100vh is the height of the top section)
    min-height: $imageHeight;
    margin-top: 0;
    overflow: hidden;
    width: 100%;

    // Bit of a hack to hide the bulb wire in the overscroll region (visible when scrolling "past" the top of the page in Chrome)
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100vh;
      top: -100vh;
      left: 0;
      background: $white;
    }
  }

  .EnergySectorHero-image {
    top: calc(80vh - #{($imageHeight / 2)});

    @media #{$tablet} {
      top: calc(50vh + #{$header-height-md});
    }

    @media #{$laptop} {
      top: calc(50vh + #{$header-height-md});
    }
  }
}

// CONTENT STYLING ========

.EnergySectorHero-title {
  margin-top: 0;
  margin-bottom: $v / 2;
}

.EnergySectorHero-subtitle {
  margin-top: 0;
  margin-bottom: $v * 1.5;
}

.EnergySectorHero-highlightedText,
.EnergySectorHero-title--highlighted {
  color: $flamingo;
  font-style: inherit;
}

.EnergySectorHero-clientList {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;

  @media #{$laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -($h * 0.66);
    margin-right: -($h * 0.66);
  }
}

.EnergySectorHero-client {
  margin-bottom: $v / 2;
  justify-content: center;
  padding-right: $v / 3;
  padding-left: $v / 3;

  img {
    height: 25px;

    @media #{$tablet} {
      height: 40px;
    }

    @media #{$laptop} {
      height: 45px;
      padding-right: $v / 2;
      padding-left: $v / 2;
    }
  }
}

// ========================
// MODIFIERS
// ========================
.EnergySectorHero-section--dark {
  color: $white;
  background-color: $midnight;
}

.EnergySectorHero-section--top {
  height: 0;
  min-height: $heroHeightMobile;

  @media #{$tablet} {
    min-height: $heroHeightTablet;
  }

  @media #{$laptop} {
    min-height: $heroHeightLaptop;
  }
}

.EnergySectorHero-section--bottom {
  align-items: stretch;
  padding-top: $v;
  height: auto;

  @media #{$tablet} {
    padding-top: $v * 12;
    padding-bottom: $v * 11;
    align-items: center;
  }
}
