// ============
// $PADDING
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

@mixin padding($namespace: "") {
  // Padding top ============
  .u-#{$namespace}pT-0 { padding-top: 0 !important; }
  .u-#{$namespace}pT-sm { padding-top: $v / 2 !important; }
  .u-#{$namespace}pT-md { padding-top: $v !important; }
  .u-#{$namespace}pT-lg { padding-top: $v * 2 !important; }
  .u-#{$namespace}pT-xl { padding-top: $v * 3 !important; }
  // Padding right ============
  .u-#{$namespace}pR-0 { padding-right: 0 !important; }
  .u-#{$namespace}pR-sm { padding-right: $v / 2 !important; }
  .u-#{$namespace}pR-md { padding-right: $v !important; }
  .u-#{$namespace}pR-lg { padding-right: $v * 2 !important; }
  .u-#{$namespace}pR-xl { padding-right: $v * 3 !important; }
  // Padding bottom ============
  .u-#{$namespace}pB-0 { padding-bottom: 0 !important; }
  .u-#{$namespace}pB-sm { padding-bottom: $v / 2 !important; }
  .u-#{$namespace}pB-md { padding-bottom: $v !important; }
  .u-#{$namespace}pB-lg { padding-bottom: $v * 2 !important; }
  .u-#{$namespace}pB-xl { padding-bottom: $v * 3 !important; }
  // Padding left ============
  .u-#{$namespace}pL-0 { padding-left: 0 !important; }
  .u-#{$namespace}pL-sm { padding-left: $v / 2 !important; }
  .u-#{$namespace}pL-md { padding-left: $v !important; }
  .u-#{$namespace}pL-lg { padding-left: $v * 2 !important; }
  .u-#{$namespace}pL-xl { padding-left: $v * 3 !important; }
}

@include padding();
@media #{$mobile-only} { @include padding("sm-"); }
@media #{$tablet} { @include padding("md-"); }
@media #{$laptop} { @include padding("lg-"); }
@media #{$desktop} { @include padding("xl-"); }
// @media #{$widescreen} { @include padding("xx-"); }
