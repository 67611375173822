/// ========================
// INPUT LABELS
// ========================

// ============
// Focused State
// ============
input:focus + .Input-label,
textarea:focus + .Input-label {
  @extend %fineprint;
  top: $v / 1.5;
  color: $midnight;
  caret-color: $flamboyant-flamingo;

  &::after {
    visibility: visible;
    animation: UnderlineAdd 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-50%) scaleX(1);
  }
}

// ============
// Dirty State
// ============
.Input.has-value input + .Input-label,
.Textarea.has-value textarea + .Input-label {
  @extend %fineprint;
  top: $v / 1.5;
}
