// ============
// $MEDIA OBJECT
// ============
// This uses combined Media versions from:
// Simon Smith: https://github.com/simonsmith/components-media
// Harry Roberts: https://github.com/csswizardry/inuit.css/blob/master/objects/_media.scss

// NOTE, .Media_body element can't be a <span>
// Usage:
// <div class="Media">
//   <img class="Media-object" src="assets/images/checklist-tick.png">
//   <p class="Media-body">
//     A medical certificate and a completed Self-Assessment questionaire if you have a health condition, injury or disability that stops you working full-time
//   </p>
// </div>


// ============
// Clear floats
// ============
.Media,
.Media-body {
  overflow: hidden;
}

// ============
// Images and video
// ============

.Media-object img,
.Media-object svg {
  display: block;
}

// ========================
// MODIFIERS
// ========================

.Media-object {
  float: left;
}
