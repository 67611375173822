// ============
// $LOADER
// ============

.Loader {
  width: rem(50px);
  height: rem(11px);

  svg {
    display: block;
  }
}
