// ========================
// FILE UPLOAD
// ========================

.FileUpload {
  position: relative;
  margin-bottom: $v * 2;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

// ========================
// ELEMENTS
// ========================

.FileUpload-label {
  @include font(16px, 20px);
  @extend %font-regular;
  color: $midnight;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  padding: {
    top: $v * 1.5;
    right: $h * 3;
    bottom: $v / 1.5;
    left: 0;
  }

  // === underline
  &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: $v / 3;
    left: 50%;
    width: 100%;
    height: 1px;
    background-color: $midnight;
    transform: translateX(-50%);
    z-index: 1;
  }

  // Paperclip ============
  &::before {
    @extend %icon;
    @extend .icon-attach;
    @include font(24px);
    text-align: center;
    width: $v;
    height: $v;
    pointer-events: none;
    position: absolute;
    top: $v * 1.25;
    right: 0;
  }
}

.FileUpload-advisory {
  position: absolute;
  left: 0;
  @include font(14px);
}

.FileUpload-list {
  list-style-type: none;
  padding: 0;
  margin: {
    top: 0;
    right: 0;
    bottom: $v;
    left: 0;
  }
}

.FileUpload-file {
  display: flex;
  justify-content: space-between;
  padding-bottom: $v;

  &:last-child {
    padding-bottom: 0;
  }
}

.FileUpload-file-remove {
  @extend %btn-reset;
  padding: 0;

  .icon {
    @include font(24px);
  }
}

// ========================
// UI STATES
// ========================

.FileUpload input:focus + .FileUpload-label {
  color: $teal;

  &::after {
    background-color: $teal;
  }
}
