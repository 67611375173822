// =================================
// CASE STUDY STATISTIC: LFT03, RGH03
// =================================

.CaseStudy-statistic {
  margin-top: rem(120px);

  @media #{$tablet} {
    margin-top: rem(168px);
  }
}

// ========================
// ELEMENTS
// ========================

.CaseStudy-statistic-image {
  width: 100%;
  height: rem(467px);

  @media #{$laptop} {
    @include fit-media(223,384);
  }
}
