// ============
// HEADER
// ============

// Placeholder, used in the header AND menu
%header-sizing {
  height: $header-height-sm;

  padding-top: 1.25rem;
  padding-right: 1.5rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;

  @media #{$tablet} {
    height: $header-height-md;

    padding-top: 2.638888888888889vw;
    padding-right: 4.166666666666666vw;
    padding-bottom: 2.083333333333333vw;
    padding-left: 4.166666666666666vw;
  }
}

.Header {
  @extend %header-sizing;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-header;
  display: flex;
  align-items: center;
}

// ========================
// ELEMENTS
// ========================

.Header-logo {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.65vw;

  a {
    display: flex;
    align-items: center;
    margin-top: 1.2vw;
    

    svg {
      width: max(6.9375rem, 9.677777777777777vw);
      height: auto;
    }

    svg, svg path {
      transition: fill .25s ease;
    }

    .Header-logo-if {
      width: max(10.7613753878rem, 15vw);
      margin-left: 1vw;
      transform: translateY(10%);
    }
    &:hover, &:focus, &:active {
      svg, svg path {
        fill: red;
      }
    }
  }

}

// ========================
// UI STATES
// ========================

.Header {
  &.is-ready {
    will-change: transform;
    transition-property: transform;
  }

  &.is-pinned {
    transform: translateY(0%);
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 250ms;
  }

  &.is-unpinned {
    transform: translateY(-100%);
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 200ms;
  }
}
