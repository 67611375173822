// ============
// $BUTTON
// ============

$button-bg: $flamingo;
$button-color: $white;
$button-padding-v: rem(18px);
$button-padding-h: rem(30px);

// @NOTE: Button structure is important
//
// {{inputs/-button
//   type="secondary"         1. Types are 'primary' and 'secondary' (primary by default).
//   content="Hello World!"   2. Used for the button content and a data attribute needed for animation.
// }}

// ========================
// Buttons
// ========================

// Base button styles
.Button {
  @extend %font-bold !optional;
  @include font(16px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  height: $button-height;
  margin: 0;
  padding: 0 $button-horizontal-padding;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: normal;
  border-radius: $button-border-radius;
  min-width: $button-min-width;
  transition: all $button-transition-speed ease-in-out;
  overflow: hidden;

  span {
    position: relative;
    transition: all 0.3s ease;
    padding-right: $h * 2;
    transform: translateX(25px);
    opacity: 1;
  }

  .LinkArrow {
    width: rem(39px);
    height: 8px;
    position: absolute;
    top: 50%;
    margin-top: rem(-4px);
    right: 0;
  }

  .LinkArrow g {
    stroke: $white;
  }

  .Loader {
    position: absolute;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  // ========================
  // UI STATES
  // ========================

  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &.is-disabled,
  &.is-loading {
    text-decoration: none;
    outline: none;
  }

  &.is-loading {
    cursor: wait;

    .Loader {
      opacity: 1;
      transform: translateX(0);
    }

    span {
      transform: translateX(calc(25px + 50%));
      opacity: 0;
    }
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }
}


// ========================
// MODIFIERS
// ========================

// === Full width
.Button--block {
  width: 100%;
}

.Button--primary {
  .Loader path { stroke: $white; }
}

.Button--secondaryLight {
  .LinkArrow g {
    stroke: $secondary-dark;
  }
}

.Button--secondaryDark {
  .Loader path { stroke: $white; }

  .LinkArrow g {
    stroke: $white;
  }
}


// ============
// STATES
// ============
.Button:hover,
.Button:active,
.Button:focus {
  @extend %arrow-hover;
  span { transform: translateX(0); }
}
