.ProductItems {
  max-width: 100%;
  margin-bottom: 36px; 

  @media #{$tablet} {
    margin-bottom: 88px;
  }

  @media #{$desktop} {
    margin-bottom: 128px;
  }
}

.ProductItem-dividerWrapper {
  display: flex;
  flex-direction: column;

  &:not(:last-child):after {
    content: '';
    margin-top: 16px;
    margin-bottom: 16px;
    height: 1px;
    width: 100%;
    background-color: #edeeee;

    @media #{$tablet} {
      flex-direction: row;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    @media #{$widescreen} {
      margin-top: 32px;
    }
  }
}

.ProductItem {
  position: relative;
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.ProductItem-title {
  margin-top: 0px;
  margin-bottom: 0px;

  @media #{$tablet} {
    width: calc(100% / 3 - 64px);
    margin-right: 64px;
  }

  @media #{$widescreen} {
    width: calc(100% / 3 - 120px);
    margin-right: 120px;
  }
}

.ProductItem-blurbSubTitle {
  font-family: $base-font-family;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 16px;
  color: $rhino;

  @media #{$tablet} {
    margin-top: 0;
    margin-bottom: 11px;
  }

  @media #{$desktop} {
    font-size: 20px;
  }
}

.ProductItem-description {
  // @extend %small;
  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
  @media #{$tablet} {
    p {
      margin-top: 0;
    }
    width: calc(100% / 3 * 2);
  }
}
