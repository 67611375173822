// ==================
// CASE STUDY LIST
// ==================

$case-study-margin: $v / 3;
$case-study-width: calc(25% - #{$case-study-margin});
$case-study-mobile-width: calc(50% - #{$case-study-margin / 2});

.CaseStudies {
  margin-bottom: rem(168px);

  @media #{$tablet-only} {
    margin-bottom: rem(98px);
  }

  @media #{$tablet} {
    overflow: initial;
  }
}

// ========================
// ELEMENTS
// ========================

.CaseStudyList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: rem(272px);
}

.CaseStudyList-item {
  width: $case-study-mobile-width;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: $case-study-margin;

  // fix(24086): Header and Case Study elements colliding in 3D space in Safari
  // https://alphero.sifterapp.com/issues/24086
  transform: translateZ(-1000px);
  margin-bottom: $v / 2;

  // The first child of each row of 2 gets no margin
  &:nth-child(2n+1) {
    margin-left: 0;
  }

  @media #{$tablet} {
    width: $case-study-width;
    margin-bottom: $v;

    // reset the small screen margin removal
    &:nth-child(2n+1) {
      margin-left: $case-study-margin;
    }

    // The first child of each row of 4 gets no margin
    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }

  &:hover {
    position: relative;
    z-index: $z-capsule;
  }
}

// ========================
// MODIFIERS
// ========================

.CaseStudyList-item--cta {
  min-width: $case-study-width;
  flex-shrink: 0;
  flex-grow: 1;

  > div {
    height: 100%;

    a {
      text-decoration: none;
    }

    .Link {
      @extend .u-textBold;
      @extend .u-textNoWrap;

      @include font(14px);

      @media #{$tablet} {
        @include font(16px);
      }
  
      @media #{$desktop} {
        @include font(20px);
      }
    }

    &:hover {
      .Link {
        color: $flamingo;
      }

      .LinkArrow-head {
        stroke-dashoffset: 0;
        opacity: 1;
      }

      .LinkArrow g {
        stroke: $secondary;
      }

      .LinkArrow-body {
        stroke-dashoffset: 0;
        opacity: 1;
        animation: none;
      }
    }
  }

  @media #{$tablet} {
    margin-right: $case-study-margin;
  }

  // At laptop up it takes up 100% of the space
  .CaseStudyCTA {
    width: 100%;
  }
}

.CaseStudyList-item--marginBottom {
  margin-bottom: $v / 2;

  @media #{$laptop} {
    margin-bottom: $v;
  }

  @media #{$desktop} {
    margin-bottom: $v * 2;
  }
}
