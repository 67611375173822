.LetsTalkModule {
  background-color: #050058;
}

.LetsTalkModule-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LetsTalkModule-paragraph {
  font-family: $base-font-family;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #ffffff;
}

.LetsTalkModule-link {
  margin-top: 16px;
  height: fit-content;
  font-family: $base-font-family;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.4;
  color: #ffffff;

  @media #{$tablet} {
    margin-top: 13px;
  }
}
