.VideoProgressControl {
  flex-grow: 1;
  height: 8px;
  position: relative;
  background: rgba($white, 0.5);
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;

  @media #{$tablet} {
    height: 10px;
    border-radius: 5px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }

  // increase the tap area on small screens
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 300%;
    left: 0;
    top: -100%;

    @media #{$laptop} {
        content: none;
    }
  }
}

.VideoProgressControl-progress {
  background: $midnight;
  border-radius: 4px;
  height: 100%;
  pointer-events: none;

  @media #{$tablet} {
    border-radius: 5px;
  }
}
