// ========================
// CTAMODULE
// ========================

$module-spacing: 16px;
$input-width-tablet: rem(324px);

.CTAModule {
  background-color: $gentler-rhino;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  @media #{$tablet} {
    padding-top: rem(48px);
    padding-bottom: rem(48px);
  }

  @media #{$laptop} {
    padding-top: rem(56px);
    padding-bottom: rem(56px);
  }

  @media #{$desktop} {
    padding-top: rem(72px);
    padding-bottom: rem(72px);
  }

  .FancyRule--drawn {
    margin-top: $module-spacing;
    margin-bottom: $module-spacing;
  }
}

// ========================
// ELEMENTS
// ========================
.CTAModule-textContent {
  @media #{$tablet} {
    margin-bottom: $module-spacing * 2;
  }

  @media #{$laptop} {
    width: 50%;
    margin-bottom: 0;
  }

  &-emailIcon {
    display: block;
    background-image: url("/assets/images/interface/email.svg");
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
  }

  &-heading {
    @extend %h4;
    margin: 0;

    @media #{$desktop} {
      @include font(32px);
    }
  }

  &-description {
    margin: 0;

    @media #{$widescreen} {
      @include font(20px);
    }
  }
}

.CTAModule-submissionContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
    align-items: flex-end;
  }

  @media #{$laptop} {
    padding-top: rem(8px);
    width: 50%;
    flex-direction: column;
    align-items: stretch;
    margin-left: 94px;
  }

  @media #{$desktop} {
    padding-top: 0;
    flex-direction: row;
    align-items: center;
  }

  &-success {
    font-family: $base-font-family;

    @media #{$tablet} {
      width: calc(100% - #{$module-spacing});
    }

    @media #{$laptop} {
      width: calc(100% - 94px);
      margin: 0 0 $v * 1.5 0;
    }

    @media #{$desktop} {
      width: 100%;
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .Button {
    width: 100%;
    height: 56px;
    color: $white;

    @media #{$tablet} {
      margin-bottom: rem(8px);
      width: calc(50% - #{$module-spacing});
      margin-left: $module-spacing;
    }

    @media #{$laptop} {
      width: calc(100% - 94px);
      margin: 0;
    }

    @media #{$desktop} {
      width: calc(50% - #{$module-spacing});
      margin-left: $module-spacing;
    }
  }

  .Input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px $gentler-rhino inset;
    }

    width: 100%;
    margin-bottom: rem(32px);

    @media #{$tablet} {
      margin-bottom: 0;
      width: calc(50% - #{$module-spacing});
      margin-right: $module-spacing;
    }

    @media #{$laptop} {
      width: calc(100% - 94px);
      margin: {
        top:  rem(-41px);
        right: 0;
        bottom: rem(28px);
        left: 0;
      };
    }

    @media #{$desktop} {
      width: calc(50% - #{$module-spacing});
      margin-right: $module-spacing;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .FormError {
    position: absolute;
  }
}
