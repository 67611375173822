// ============
// $LINK
// ============

.Link {
  @extend %font-bold !optional;
  @include font(14px);
  color: $primary;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  height: $button-height;
  cursor: pointer;
  margin: 0;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: normal;
  border: none;
  transition: all $button-transition-speed ease-in-out;

  span {
    position: relative;
    transition: transform 0.3s ease;
    padding-right: rem(45px);
  }

  .LinkArrow {
    width: rem(39px);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    animation: none;
  }

  .LinkArrow-body {
    stroke-dashoffset: 10;
    opacity: 1;
    animation: none;
    transition: all 250ms ease-in-out;
  }

  .LinkArrow g {
    stroke: $text;
  }

  // ========================
  // UI STATES
  // ========================

  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &.is-disabled,
  &.is-loading {
    text-decoration: none;
    outline: none;
    color: $primary;
  }

  &.is-loading {
    cursor: wait;
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }
}

// ========================
// VARIATIONS
// ========================

.Link--light {
  color: $white;

  .LinkArrow g {
    stroke: currentColor;
  }
}

// ============
// STATES
// ============
.Link:hover,
.Link:active,
.Link:focus {
  color: $secondary;

  .LinkArrow-head {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  .LinkArrow g {
    stroke: $secondary;
  }

  .LinkArrow-body {
    stroke-dashoffset: 0;
    opacity: 1;
    animation: none;
  }
}

.Link--light:hover,
.Link--light:active,
.Link--light:focus {
  color: $white;

  .LinkArrow g {
    stroke: $white;
  }
}

.Link--large {
  @include font(16px);

  @media #{$tablet} {
    @include font (20px);
  }
}

.Link--back {
  color: $secondary;
  font-size: rem(14px);

  @media #{$tablet} {
    font-size: rem(16px);
  }

  .LinkArrow {
    right: auto;
    left: 0;
    transform: translateY(-50%) rotateY(180deg);
  }

  .LinkArrow g {
    stroke: $secondary;
  }

  span {
    padding-left: rem(45px);
    padding-right: 0;
    margin-left: -10px;
  }
}

