// ============
// $INPUT
// ============

.Input {
  position: relative;

  input {
    @extend %font-regular;
    color: $midnight;
    box-shadow: none;
    line-height: rem(20px);
    background-color: $white;
    display: block;
    appearance: none;
    outline: none;
    width: 100%;
    border: none;
    caret-color: $flamboyant-flamingo;
    padding: {
      top: $v * 1.5;
      right: 0;
      bottom: $v / 1.5;
      left: 0;
    }

    &::-webkit-input-placeholder { color: rgba(0, 0, 0, 0.38); }
    &::-moz-placeholder { color: rgba(0, 0, 0, 0.38); opacity: 1; }
    &:-ms-input-placeholder { color: rgba(0, 0, 0, 0.38); }
    &:-moz-placeholder { color: rgba(0, 0, 0, 0.38); }
  }

  // === grey underline
  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: $v / 3;
    left: 50%;
    width: 99%; // Tweak to stop 1px midnight line peeking through on ends
    height: 1px;
    background-color: $midnight;
    transform: translateX(-50%);
    z-index: 1;
  }
}

// ========================
// ELEMENTS
// ========================

.Input-label {
  @include font(16px, 24px);
  @extend %font-regular !optional;
  display: block;
  color: $midnight;
  top: $v * 1.5;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  // === teal underline
  &::after {
    visibility: hidden;
    display: block;
    content: '';
    position: absolute;
    bottom: $v / 3;
    left: 50%;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    background-color: $teal;
    transform: translateX(-50%) scaleX(0);
    animation: UnderlineRemove 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;
    caret-color: $flamboyant-flamingo;
  }
}

// ==========
// MODIFIERS
// ==========
.Input--noBackgroundColor {
  input {
    background-color: inherit;
  }
}


// ============
// ANIMATIONS
// ============
@keyframes UnderlineRemove {
  0% {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
    visibility: visible;
  }

  to {
    transform: translateX(-50%) scaleX(1);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes UnderlineAdd {
  0% {
    transform: translateX(-50%) scaleX(0);
  }

  to {
    transform: translateX(-50%) scaleX(1);
  }
}
