.ProductExamples {
  position: relative;
  max-width: 100%;
}

.ProductExamples-title {
  margin: 0;
}

.ProductExamples-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;

  @media #{$laptop} {
    align-items: center;
  }
}


.ProductExamples-grid {
  @media #{$tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }

  @media #{$laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.ProductExamples-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0 !important;
}

.ProductExample-full-width {
  position: relative;
  width: 100vw;

  @media #{$tablet} {
    width: 100%;
  }
}

.ProductExample-image-resizer {
  @include fit-media(680, 453);
}

.ProductExample-button {
  background-color: $flamingo;
  padding-right: 12px;
  padding-left: 12px;
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 0;
}

.ProductExample-button-dark {
  background-color: $primary;
}

.ProductExample-text-area {
  width: 100%;
}

.ProductExample-header {
  color: $primary;
  font-weight: 600;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;
}

.ProductExamples-headerLink {
  display: none;
  height: fit-content;

  span {
    position: static;
  }

  @media #{$tablet} {
    padding-left: 48px;
    white-space: nowrap;
    display: block;
  }


}

.ProductExample-paragraph {
  color: $primary;
  // @extend %small;
  margin-bottom: 40px;
  margin-top: 0;

  @media #{$tablet} {
    margin-bottom: 32px;
  }

  @media #{$laptop} {
    margin-bottom: 56px;
  }

  @media #{$desktop} {
    margin-bottom: 72px;
  }

  @media #{$widescreen} {
    margin-bottom: 88px;
  }
}
