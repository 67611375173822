// ========================
// AUTHOR
// ========================
.Author {
  display: flex;
  align-items: center;
  margin-bottom: $v / 2;
  padding: $v / 1.5 $h / 1.5;
  padding-bottom: 0;
}

// ========================
// ELEMENTS
// ========================
.Author-portrait {
  width: 40px;
  height: 40px;
  margin-right: $h / 2;

  .ImageWrapper {
    border-radius: 50%;
  }
}

.Author-name {
  @include font(16px);
}

.Author-role {
  @include font(14px);
}

.Author-name,
.Author-role {
  margin: 0;
  line-height: 1.25;
}
