// ==================
// ANIMATOR
// ==================

.Animator {
  width: 100%;
  height: 100%;
  position: relative;
}

// ========================
// ELEMENTS
// ========================

.Animator-lottie {
  width: 100%;
  height: 100%;
}

.Animator-video {
  width: 100%;
}

.Animator-video--triggered {
  // Needed to fix the follow up issue on https://alphero.sifterapp.com/issues/23871
  // The issue is that Safari on iOS 12 doesn't display the video when it starts playing for some
  // completely unknown reason. It's seriously weird - we're not hiding it anywhere, Safari just
  // decides it can't be bothered showing the playing video. To fix it we add this class from the
  // JS when the video starts playing, and doing so triggers Safari to re-evaluate it's choices.
  // The choice of opacity is arbitrary - z-index, transform, and potentially anything seems to do the trick too.
  opacity: 1;
}

.Animator-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.Animator-exitLottie {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
}

// ========================
// MODIFIERS
// ========================

.Animator--hasExitLottie {
  .Animator-lottie {
    z-index: 10;
  }
}

.Animator-lottie {
  height: 100%;
}

// ========================
// UI STATES
// ========================

.Animator-lottie.is-hidden {
  opacity: 0;
  height: 100%;
}

.Animator-exitLottie.is-showing {
  opacity: 1;
  height: 100%;
  width: 100%;
}
