.DebugIcons {
  text-align: center;
  padding: $v / 4;
  display: flex;
  flex-direction: column;
  background-color: $primary;
  font-size: font(32px);
  color: $white;

  > span.SVG {
    width: rem(32px);
    height: rem(32px);
  }

  > span.SVG {
    &[data-icon="alphero-logo"] {
      width: rem(150px);
      height: rem(40px);
    }
  }
}

.DebugIcons--light {
  background-color: $white;
  color: $primary;
}

// ==================
// TEMP FULL IMG
// ==================
.Menu,
.Footer-content {
  img {
    width: 100%;
  }
}

.Footer-content {
  img {
    flex-shrink: 0; // Needed for IE
    max-width: 50%;
  }
}

// Debug - Styles for ModuleNotFound component
.CaseStudy-moduleNotFound {
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 8px;
  align-items: center;

  p {
    padding-left: 8px;
    margin: 0;
    background-color: yellow;
  }

  span {
    margin-top: 8px;
    font-weight: 1000;
    background-color: yellow;
    padding: 8px;
  }

  background:
    repeating-linear-gradient(
      45deg,
      $red,
      $red 10px,
      $gentle-rhino 10px,
      $gentle-rhino 20px
    );
}

.Todo {
  height: rem(354px);

  @media #{$tablet} {
    height: rem(800px);
  }
}

.Todo-content {
  background-color: #ed042e;
  text-align: center;
  height: 100%;
}
