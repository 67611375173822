// ========================
// ThreeColumnText
// ========================
// @note: Found on the Home page
// ========================
.ThreeColumnText {

  .LinkArrow g {
    stroke: $white;
  }

  margin-top: rem(-200px);
  margin-bottom: rem(56px);

  @media #{$tablet} {
    margin-top: 0;
  }
  @media #{$laptop} {
    margin-bottom: rem(72px);
  }
  @media #{$desktop} {
    margin-bottom: rem(88px);
  }
  @media #{$widescreen} {
    margin-bottom: rem(96px);
  }

}

.ThreeColumnText-headerLink {
  transition: all 0.3s ease;
  color: $primary;
  text-decoration: none;
}

.ThreeColumnText-column {
  overflow: hidden;

  h3 {
    white-space: nowrap;
  }

  .LinkArrow {
    width: 40px;

    @media #{$laptop} {
      width: initial;
    }

    margin-left: 16px;
  }

  &:hover {
    .ThreeColumnText-headerLink {
      color: $flamingo;
    }

    .LinkArrow g {
      stroke: $flamingo;
    }
  
    @extend %arrow-hover;
    span { transform: translateX(0); }
  }

  @media #{$mobile-only} {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 0 !important;
  }
}

.ThreeColumnText-seeMore {
  height: fit-content;

  span {
    position: static;
  }
}

.ProductExamples-headerLink {
  display: none;
  height: fit-content;

  span {
    position: static;
  }

  @media #{$tablet} {
    padding-left: 48px;
    white-space: nowrap;
    display: block;
  }
}
