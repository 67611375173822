// ============
// $WIDTHS
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

@mixin widths($namespace: "") {
  // Whole ============
  .u-#{$namespace}width1of1 { width: 100% !important; } // 100%
  // Halves ============
  .u-#{$namespace}width1of2 { width: #{(1 / 2) * 100%} !important; } // 50%
  // .u-#{$namespace}width2of2 { @extend .u-#{$namespace}width1of1 !optional; }
  // Thirds ============
  .u-#{$namespace}width1of3 { width: #{(1 / 3) * 100%} !important; } // 33.33333%
  .u-#{$namespace}width2of3 { width: #{(2 / 3) * 100%} !important; } // 66.66666%
  // .u-#{$namespace}width3of3 { @extend .u-#{$namespace}width1of1 !optional; }
  // Quarters ============
  .u-#{$namespace}width1of4 { width: #{(1 / 4) * 100%} !important; } // 25%
  .u-#{$namespace}width2of4 { @extend .u-#{$namespace}width1of2 !optional; }
  .u-#{$namespace}width3of4 { width: #{(3 / 4) * 100%} !important; } // 75%
  // .u-#{$namespace}width4of4 { @extend .u-#{$namespace}width1of1 !optional; }
  // Fifths ============
  .u-#{$namespace}width1of5 { width: #{(1 / 5) * 100%} !important; } // 20%
  // Sixths ============
  .u-#{$namespace}width1of6 { width: #{(1 / 6) * 100%} !important; } // 16.66666%
  // .u-#{$namespace}width2of6 { @extend .u-#{$namespace}width1of3 !optional; }
  // .u-#{$namespace}width3of6 { @extend .u-#{$namespace}width1of2 !optional; }
  // .u-#{$namespace}width4of6 { @extend .u-#{$namespace}width2of3 !optional; }
  .u-#{$namespace}width5of6 { width: #{(5 / 6) * 100%} !important; } // 83.3333%
  // .u-#{$namespace}width6of6 { @extend .u-#{$namespace}width1of1 !optional; }
  // Twelfths ============
  .u-#{$namespace}width1of12 { width: #{(1 / 12) * 100%} !important; } // 8.3333%
  .u-#{$namespace}width2of12 { @extend .u-#{$namespace}width1of6 !optional; } // 16.6666%
  .u-#{$namespace}width3of12 { @extend .u-#{$namespace}width1of4 !optional; } // 25%
  .u-#{$namespace}width4of12 { @extend .u-#{$namespace}width1of3 !optional; } // 33.3333%
  .u-#{$namespace}width5of12 { width: #{(5 / 12) * 100%} !important; } // 41.6666%
  .u-#{$namespace}width6of12 { @extend .u-#{$namespace}width1of2 !optional; } // 50%
  .u-#{$namespace}width7of12 { width: #{(7 / 12) * 100%} !important; } // 58.3333%
  .u-#{$namespace}width8of12 { @extend .u-#{$namespace}width2of3 !optional; } // 66.6666%
  .u-#{$namespace}width9of12 { @extend .u-#{$namespace}width3of4 !optional; } // 75%
  .u-#{$namespace}width10of12 { @extend .u-#{$namespace}width5of6 !optional; } // 83.3333%
  .u-#{$namespace}width11of12 { width: #{(11 / 12) * 100%} !important; } // 91.6666%
  .u-#{$namespace}width12of12 { @extend .u-#{$namespace}width1of1 !optional; } // 100%
}

@include widths();
@media #{$mobile-only} { @include widths("sm-"); }
@media #{$tablet} { @include widths("md-"); }
@media #{$laptop} { @include widths("lg-"); }
@media #{$desktop} { @include widths("xl-"); }
@media #{$widescreen} { @include widths("xx-"); }
