// ============
// LETS TALK
// ============
// Styles for the Lets talk component

// .LetsTalk {}

// ========================
// ELEMENTS
// ========================

.LetsTalk-Form-title {
  margin-bottom: $v / 3;
}

.LetsTalk-email-message {
  padding-bottom: $v / 1.5;

  p {
    @extend %font-bold;
    margin-top: $v / 3;
  }
}

.LetsTalk-button-message {
  @media #{$mobile-only} {
    width: 100%;
    padding: 0;
  }
}

.LetsTalk-is-human {
  margin-left: 16px;
}

.LetsTalk-newsletter {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 2px;
  border: solid 1px #050158;

  &:hover {
    border: solid 1px #00e8db;
  }
}

.LetsTalk-newsletter-selected {
  border: solid 1px #00e8db;
}

.LetsTalk-newsletter-text {
  display: flex;
  flex-direction: column;
}

.LetsTalk-newsletter-text-title {
  margin-top: 0;
  margin-bottom: 0;
}

.LetsTalk-newsletter-text-checkbox {
  display: flex;
  align-items: center;
}

.LetsTalk-newsletter-animation {
  width: 64px;
  height: 64px;
}
