%loading-pulse {
  animation: placeholder-pulse 1s both normal infinite ease-in-out;
}

@mixin loading-text($text: 'Loading cool content (and reticulating the splines)', $colour: $gentle-rhino) {
  color: transparent;
  text-shadow: none;

  &::before {
    content: $text;
    animation: placeholder-pulse-opacity 1s both normal infinite ease-in-out;
    background: $colour;
    font-size: 0.9em;
    line-height: 1.2;
  }
}
