.QuotePost {
  background-color: $white;
  border: none;
  padding: $v $h;
  position: relative;
  margin-bottom: $v / 2;
  will-change: transform, box-shadow;

  @media #{$laptop} {
    margin-bottom: $v;
  }
}

.QuotePost-text {
  @include font(20px);
  @extend .u-textBold;
  line-height: 1.3;

  text-align: center;
  margin: 0;

  @media #{$widescreen} {
    @include font(24px);
  }
}

.QuotePost-author {
  @include font(14px);

  text-align: center;
  margin-bottom: 0;
}

.QuotePost-icon {
  background-image: url("/assets/images/interface/quotemarks.svg");
  width: 64px;
  height: 64px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(8px);
}

.QuotePost-cornerDecoration {
  background-image: url("/assets/images/interface/element-corner.svg");
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
}

.QuotePost-cornerDecoration--topLeftCorner {
  top: 0;
  left: 0;
}

.QuotePost-cornerDecoration--topRightCorner {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}

.QuotePost-cornerDecoration--bottomRightCorner {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.QuotePost-cornerDecoration--bottomLeftCorner {
  bottom: 0;
  left: 0;
  transform: rotate(270deg);
}