// ============
// $TEXTAREA
// ============

.Textarea {
  position: relative;

  textarea {
    @extend %font-regular;
    color: $midnight;
    caret-color: $flamboyant-flamingo;
    line-height: rem(20px);
    background: #FFF;
    display: block;
    appearance: none;
    outline: none;
    width: 100%;
    border: none;
    resize: none;
    padding: {
      top: $v * 1.5;
      right: 0;
      bottom: $v / 1.5;
      left: 0;
    };

    &::-webkit-input-placeholder { color: rgba(0, 0, 0, 0.38); }
    &::-moz-placeholder { color: rgba(0, 0, 0, 0.38); opacity: 1; }
    &:-ms-input-placeholder { color: rgba(0, 0, 0, 0.38); }
    &:-moz-placeholder { color: rgba(0, 0, 0, 0.38); }
  }

  // === grey underline
  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: $v / 3;
    left: 50%;
    width: 99%; // Tweak to stop 1px midnight line peeking through on ends
    height: rem(1px);
    background-color: $midnight;
    transform: translateX(-50%);
    z-index: 1;
  }
}
