// ============
// $PADDING
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

// Specify the proportional offset after an element.
// Intentional redundancy build into each set of unit classes.
// Supports: 2, 3, 4, 6, 12 section

@mixin offset-after($namespace: "") {
  // None ============
  .u-#{$namespace}afterNone { margin-right: 0 !important; }
  // Halves ============
  .u-#{$namespace}after1of2 { margin-right: #{(1 / 2) * 100%} !important; }
  // Thirds ============
  .u-#{$namespace}after1of3 { margin-right: #{(1 / 3) * 100%} !important; }
  .u-#{$namespace}after2of3 { margin-right: #{(2 / 3) * 100%} !important; }
  // Quarters ============
  .u-#{$namespace}after1of4 { margin-right: #{(1 / 4) * 100%} !important; }
  .u-#{$namespace}after2of4 { @extend .u-#{$namespace}after1of2 !optional; }
  .u-#{$namespace}after3of4 { margin-right: #{(3 / 4) * 100%} !important; }
  // Sixths ============
  .u-#{$namespace}after1of6 { margin-right: #{(1 / 6) * 100%} !important; }
  .u-#{$namespace}after2of6 { @extend .u-#{$namespace}after1of3 !optional; }
  .u-#{$namespace}after3of6 { @extend .u-#{$namespace}after1of2 !optional; }
  .u-#{$namespace}after4of6 { @extend .u-#{$namespace}after2of3 !optional; }
  .u-#{$namespace}after5of6 { margin-right: #{(5 / 6) * 100%} !important; }
  // Twelfths ============
  .u-#{$namespace}after1of12 { margin-right: #{(1 / 12) * 100%} !important; }
  .u-#{$namespace}after2of12 { @extend .u-#{$namespace}after1of6 !optional; }
  .u-#{$namespace}after3of12 { @extend .u-#{$namespace}after1of4 !optional; }
  .u-#{$namespace}after4of12 { @extend .u-#{$namespace}after1of3 !optional; }
  .u-#{$namespace}after5of12 { margin-right: #{(5 / 12) * 100%} !important; }
  .u-#{$namespace}after6of12 { @extend .u-#{$namespace}after1of2 !optional; }
  .u-#{$namespace}after7of12 { margin-right: #{(7 / 12) * 100%} !important; }
  .u-#{$namespace}after8of12 { @extend .u-#{$namespace}after2of3 !optional; }
  .u-#{$namespace}after9of12 { @extend .u-#{$namespace}after3of4 !optional; }
  .u-#{$namespace}after10of12 { @extend .u-#{$namespace}after5of6 !optional; }
  .u-#{$namespace}after11of12 { margin-right: #{(11 / 12) * 100%} !important; }
}

@include offset-after();
@media #{$mobile-only} { @include offset-after("sm-"); }
@media #{$tablet} { @include offset-after("md-"); }
@media #{$laptop} { @include offset-after("lg-"); }
@media #{$desktop} { @include offset-after("xl-"); }
// @media #{$widescreen} { @include offset-after("xx-"); }
