// ============
// MENUBUTTON
// ============
//
// <button className="MenuButton" type="submit" onClick={() => toggleMenu({ label: ACTION_EVENT.MENU_CLICK })}>
//   <div className="MenuButton-wrapper">
//     <i className="MenuButton-line MenuButton-line--top" />
//     <i className="MenuButton-line MenuButton-line--middle" />
//     <i className="MenuButton-line MenuButton-line--bottom" />
//     <span className="MenuButton-label">Menu</span>
//   </div>
// </button>

$btn-size:      $h * 2;
$line-width:    2px;
$line-leng-sm:  $h * 0.5;
$line-leng-md:  $h * 0.75;
$line-leng-lg:  $h;
$border-radius: $line-width;

// MENU BUTTON
.MenuButton {
  @extend %btn-reset;
  @extend %btn-padding;
  display: block;
  height: $btn-size;
  width: $btn-size;
  cursor: pointer;
  overflow: visible;
}

// ========================
// ELEMENTS
// ========================

.MenuButton-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.MenuButton-label {
  @extend %font-bold;
  @include font(16px);
  line-height: $btn-size;
  position: absolute;
  right: 0;
  top: 50%;
  transform: scale(1) translateY(-50%) translateX(-$line-leng-md);
  opacity: 0;
  will-change: color, opacity, transform;
  transform-origin: 100% 50%;
  transition: all ($timing--menuButton * 1.25) ease;
}

.MenuButton-line {
  display: block;
  position: absolute;
  background: $primary;
  height: $line-width;
  border-radius: $border-radius;
  right: 0;
  top: calc(50% - (#{$line-width} / 2));
  will-change: background, transform;
  transform-origin: 100% 50%;
}

.MenuButton-line--top {
  width: $line-leng-lg;
  transform: scaleX(1) translateY(-7px) translateX(0);
  transition:
    background $timing--menuButton ease,
    transform $timing--menuButton ease ($timing--menuButton / 3);
}

.MenuButton-line--middle {
  width: $line-leng-md;
  transform: scaleX(1) translateX(0);
  transition: all $timing--menuButton ease;
}

.MenuButton-line--bottom {
  width: $line-leng-sm;
  transform: scaleX(1) translateY(7px) translateX(0);
  transition:
    background $timing--menuButton ease,
    transform $timing--menuButton ease ($timing--menuButton / 1.5);
}

.MenuButton:hover,
.MenuButton:focus {
  width: $btn-size * 2;

  .MenuButton-label {
    color: $secondary-dark;
    opacity: 1;
    transform: scale(1) translateY(-50%) translateX(-$line-leng-md * 2.5);
  }

  .MenuButton-line--top {
    background: $secondary-dark;
    transform: scaleX(0) translateY(-7px) translateX(100%);
  }

  .MenuButton-line--middle {
    background: $secondary-dark;
    transform: scaleX(2) translateX(2px);
  }

  .MenuButton-line--bottom {
    background: $secondary-dark;
    transform: scaleX(0) translateY(7px) translateX(100%);
  }
}
