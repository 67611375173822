// ========================
// SKILL MODULE - SKL01-01a
// ========================

.Skills {
  position: relative;
  margin-top: $h * 6;

  @media #{$tablet} {
    margin-top: $h * 12;
  }
}

.SkillModule {
  position: relative;
  margin-bottom: 4px; // Magic number - related to the threshold used in Skills/index.jsx
  padding-bottom: $v * 4;

  // transition: opacity 0.5s ease-in-out;

  &:last-child {
    padding-bottom: 0;
  }

  @supports (position: sticky) {
    padding-bottom: 10rem;
    @media #{$laptop} {
      padding-bottom: 20rem;
    }
  }
}

// ========================
// ELEMENTS
// ========================

.SkillModule-overlay {
  position: absolute;
  z-index: $z-slider-controls;
  text-align: center;
  height: rem(268px);
  bottom: -104px; // from designs
  right: 0;
  overflow: hidden;

  @media #{$tablet} {
    height: rem(274px);
    bottom: -88px;
    right: auto;
    text-align: left;
  }

  @media #{$laptop} {
    height: rem(364px);
    bottom: -104px;
  }

  @media #{$desktop} {
    height: rem(448px);
    bottom: -120px;
  }
}

.SkillModule-overlay-image {
  // Mildly hackish to reposition without Safari doing annoying scroll/overflow things
  transform: translateX(10px);

  @media #{$tablet} {
    transform: none;
  }
}

.SkillModule-blurb {
  left: 0;
  transform: none;
  opacity: 1;
  transition: transform 0.5s 0.1s ease-out, opacity 0.5s 0.1s ease-out;
}

.SkillModule-overlay {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease-out;
}

// ========================
// MODIFIERS
// ========================

.SkillModule--hidden {
  .SkillModule-overlay {
    opacity: 0;
  }

  .SkillModule-blurb {
    opacity: 0;
    transform: translateY(75%);
  }
}
