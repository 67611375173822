// ============
// SKEW CAPTION
// ============

.SkewCaption {
  position: relative;
  color: $white;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: rem(172px);
  top: 0;
  left: 0;
  z-index: 1;
  padding: {
    top: rem(32px);
    bottom: rem(32px);
    right: rem(16px);
    left: rem(16px);
  }

  @media #{$tablet} {
    min-height: rem(188px);
    padding: {
      right: rem(24px);
      left: rem(24px);
    }
  }

  @media #{$laptop} {
    min-height: rem(224px);
    padding: {
      right: rem(32px);
      left: rem(32px);
    }
  }

  @media #{$desktop} {
    min-height: rem(248px);
    padding: {
      top: rem(40px);
      bottom: rem(40px);
      right: rem(40px);
      left: rem(40px);
    }
  }

  &::before {
    content: '';
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
  }
}

// ========================
// ELEMENTS
// ========================

.SkewCaption-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.SkewCaption-client {
  color: $secondary;
}

.SkewCaption-background-flipped {
  transform: scaleX(-1);
}

.SkewCaption-icon {
  flex-shrink: 0; // needed for iOS 9 - Sifter #25588
  @include font(20px, 28px);

  @media #{$laptop} {
    @include font(24px, 32px);
  }

  @media #{$desktop} {
    @include font(32px, 40px);
  }
}

.SkewCaption-wrapper {
  width: 100%;
  height: 100%;
  margin-top: $v / 2;
  margin-bottom: $v / 2;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.SkewCaption-title {
  flex-shrink: 0; // needed for iOS 9 - Sifter #25586
  margin-top: 0;
  margin-bottom: $v / 2;
  flex-shrink: 0; // needed for iOS 9 - Sifter #25586

  &:last-child {
    margin-bottom: 0;
  }
}

.SkewCaption-text {
  flex-shrink: 0; // needed for iOS 9 - Sifter #25588
  margin-top: 0;
  margin-bottom: $v / 2;

  &:last-child {
    margin-bottom: 0;
  }
}

.SkewCaption-link {
  color: $white;
  text-decoration: none;
  height: auto;

  .LinkArrow g {
    stroke: $white;
  }
}

.SkewCaption-link-title {
  margin-right: auto;
}

// ========================
// MODIFIERS
// ========================

.SkewCaption--left {
  text-align: left;
}
