// =====================
// TYPOGRAPHY SETTINGS
// =====================


// ==========================================
// STEP 1: Include the font using @font-face
// ==========================================
// Only woff is required when using webfonts: http://caniuse.com/#search=woff
// The following is example setup only:
// ==========================================



// ==========================================
// STEP 2: Setup variables and placeholders
// ==========================================
// The following is example setup only, however,
// I do not recommend changing base-font-size
// ==========================================
$base-font-size:      16px;
$base-line-height:    1.5;
$base-font-family: '"Lexend", sans-serif';


// ============
// $TYPOGRAPHY
// ============

/// Base font styles
/// ============
/// @type Map
/// ============
/// @prop {String} font-family - font stack
/// @prop {String} font-style - string e.g. italic, bold, normal
/// @prop {String | Number} font-weight - string or number

$base-font-styles: (
  regular: (
    font-family: #{$base-font-family},
    font-style: normal,
    font-weight: normal
  ),
  medium: (
    font-family: #{$base-font-family},
    font-style: normal,
    font-weight: 300
  ),
  bold: (
    font-family: #{$base-font-family},
    font-style: normal,
    font-weight: 700
  )
);


/// Base font dimensions
/// ============
/// @type Map
/// ============
/// @prop {Measurement} font-size - pixel measurement
/// @prop {Number?} line-height - optional line hight measurement (font-size * line-height)
/// @prop {Measurement?} margin-top - optional pixel measurement
/// @prop {Measurement?} margin-bottom - optional pixel measurement
/// @prop {String?} font-family - optional, name MUST match one of the names supplied in $base-font-styles, e.g. light, medium, bold.
/// @prop {Boolean?} uppercase - do you want capital letters
/// @prop {Measurement?} letter-spacing - do you want letter spacing
///
/// @NOTE: The following are required when you want multi breakpoint type:
/// @prop {Measurement} sm-size - what is the small/medium font size
/// @prop {Measurement} l-size - what is the large font size
/// @prop {Measurement} xl-size - what is the extra large font size
/// @prop {Measurement} xxl-size - what is the extra extra large font size
///
/// @NOTE: The following are required when you want the type to be fluid:
/// @prop {Measurement} min-breakpoint - when does the font start growing
/// @prop {Measurement} max-breakpoint - when does the font finish growing

$base-font-dimensions: (
  display: (
    sm-size: 48px,
    l-size: 56px,
    xl-size: 80px,
    xxl-size: 80px,
    // xxl-size: 88px,
    line-height: 1.2,
    font-family: regular
  ),
  h1: (
    sm-size: 48px,
    l-size: 56px,
    xl-size: 64px,
    xxl-size: 64px,
    // xxl-size: 72px,
    line-height: 1,
    font-family: bold
  ),
  h2: (
    sm-size: 40px,
    l-size: 48px,
    xl-size: 56px,
    xxl-size: 56px,
    // xxl-size: 64px,
    line-height: 1,
    font-family: bold
  ),
  h3: (
    sm-size: 28px,
    l-size: 40px,
    xl-size: 48px,
    xxl-size: 48px,
    // xxl-size: 56px,
    line-height: 1,
    font-family: bold
  ),
  h4: (
    sm-size: 20px,
    l-size: 24px,
    xl-size: 32px,
    xxl-size: 32px,
    // xxl-size: 40px,
    line-height: 1.25,
    font-family: bold
  ),
  h5: (
    sm-size: 20px,
    l-size: 20px,
    xl-size: 24px,
    xxl-size: 24px,
    // xxl-size: 32px,
    line-height: 1.3,
    font-family: bold
  ),
  // p1 in designs
  p: (
    sm-size: 16px,
    l-size: 16px,
    xl-size: 20px,
    xxl-size: 20px,
    // xxl-size: 24px,
    line-height: 1.5,
    font-family: regular
  ),
  // p2 in designs
  small: (
    sm-size: 14px,
    l-size: 14px,
    xl-size: 16px,
    xxl-size: 16px,
    // xxl-size: 20px,
    line-height: 1.5,
    font-family: regular
  ),
  fineprint: (
    sm-size: 12px,
    // xxl-size: 14px,
    xxl-size: 12px,
    line-height: 1.3,
    font-family: regular
  ),
);
