// ========================
// ERROR STATES
// ========================
// Span underneath the input elements

.FormError {
  @extend %fineprint;
  display: block;
  z-index: 1;
  color: $red;
  line-height: rem(20px);
  padding-left: rem(25px);
  position: relative;

  @media #{$desktop} {
    padding-left: rem(35px);
    line-height: 1.25;
  }

  &::before {
    @extend %icon !optional;
    @extend .icon-error !optional;
    font-size: 20px;
    line-height: 1;
    color: $red;
    position: absolute;
    left: 0;
    top: 0;

    @media #{$desktop} {
      top: -6px;
      font-size: 28px;
    }
  }
}

.Input.has-error,
.Textarea.has-error,
.Checkbox.has-error {
  .Checkbox-label::before {
    box-shadow: 0 0 0 rem(2px) $red;
  }

  &::after {
    visibility: visible;
    animation: UnderlineAdd 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-50%) scaleX(1);
    background-color: $flamingo;
  }
}

.Select-error {
  margin-top: 1em;
  margin-bottom: 1em;

  &::before {
    bottom: -$v;
  }
}

.Checkbox.has-error {
  // Error icon looks strange with checkboxes, removing it
  &::before {
    display: none;
  }
}

.Checkbox-error {
  @extend %fineprint;
  color: $red;

  margin-top: 1em;

  .FormError {
    margin-left: -2.8em; // Aligns the error icon to the box
  }
}
