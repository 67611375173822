// =========
// SVG ICONS
// =========

.SVG {
  display: inline-block;
  width: 100%;
  height: 100%;

  > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

#root > svg {
  display: none;
}

.SVG--lg {
  max-width: rem(64px);
  max-height: rem(64px);
}

.SVG--md {
  max-width: rem(32px);
  max-height: rem(32px);
}

.SVG--sm {
  max-height: rem(24px);
  max-width: rem(24px);
}

.SVG-xs {
  max-height: rem(16px);
  max-width: rem(16px);
}
