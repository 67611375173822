// =============================
// CASE STUDY GRID: GRD01-01a
// =============================

.CaseStudy-grid {
  text-align: center;
  margin-bottom: $v * 2;
}

.CaseStudy-grid-container {
  @media #{$tablet} {
    justify-content: center;
    align-items: center;
  }
}

.CaseStudy-grid-items {
  width: 100%;
  justify-content: space-around;
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
  vertical-align: middle;

  @media #{$tablet} {
    flex-wrap: wrap;
  }
}

.CaseStudy-grid-item {
  margin-bottom: $v;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  // Add margin at small sizes to space out the items (but only on the inside edges)
  &:nth-child(2n-1):not(:last-child) {
    margin-right: #{(1 / 12) * 100%};
  }

  &:nth-child(2n) {
    margin-left: #{(1 / 12) * 100%};
  }

  // Undo the above on tablet up
  @media #{$tablet} {
    &:nth-child(2n-1):not(:last-child) {
      margin-right: 0;
    }

    &:nth-child(2n) {
      margin-left: 0;
    }
  }
}

.CaseStudy-grid-icon {
  height: rem(120px);
  width: rem(120px);
}

.CaseStudy-grid-description {
  text-align: center;
  width: 100%; // needed to prevent IE from making this too wide
}

// Variations
.CaseStudy-grid-items--grid {
  display: inline-flex;
  justify-content: space-between;

  .CaseStudy-grid-item {
    flex-grow: 0;
    flex-shrink: 0;

    @media #{$tablet} {
      max-width: rem(96px);
    }

    @media #{$laptop} {
      max-width: rem(120px);
    }

    @media #{$desktop} {
      max-width: 5 / 12 * 100%;
    }
  }

  .CaseStudy-grid-icon {
    @media #{$tablet} {
      height: rem(96px);
      width: rem(96px);
    }

    @media #{$laptop} {
      height: rem(120px);
      width: rem(120px);
    }
  }
}

.CaseStudy-grid-items--row {
  .CaseStudy-grid-item {
    @media #{$tablet} {
      margin-bottom: 0;
    }
  }

  .CaseStudy-grid-icon {
    @media #{$tablet} {
      height: auto;
      width: 100%;
    }
  }
}
