// =============================
// WORK WITH US - Floating Image
// =============================

$peek-amount-lg: 90px;
$peek-timing: 0.5s;

%sticky-man-dimensions {
  width: rem(98px);
  height: rem(180px);

  @media #{$tablet} {
    width: rem(168px);
    height: rem(272px);
  }
}

// ========================
// ELEMENTS
// ========================

.WorkWithUs {
  z-index: $z-work-with-us;
  top: auto;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: fixed;
  transition: transform $peek-timing cubic-bezier(0.7, 0, 0.3, 1), opacity $peek-timing cubic-bezier(0.7, 0, 0.3, 1);
  transform: translateY(100%) translateX(0);

  @media #{$tablet} {
    top: 50vh;
    bottom: auto;
    display: block;
    width: $peek-amount-lg;
    float: right;
    transform: translateY(-50%) translateX(200%);
  }

  @media #{$tablet} {
    /* autoprefixer: off */
    // We use position:fixed here for browsers that don't support un-prefixed position:sticky
    // (Browsers like Safari support sticky with a browser prefix, but have bugs)

    position: fixed;
    position: sticky;

    // NOTE: Do not add anything to this block unless you're sure it doesn't need a browser prefix
  }
}

.WorkWithUs-content {
  cursor: pointer;
  color: $midnight;
  text-decoration: none;
  display: block;
  transition: transform 0.2s cubic-bezier(0.7, 0, 0.3, 1);
}

.WorkWithUs-bubble {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 70px;
  right: 46px;
  top: -50px;

  @media #{$tablet} {
    width: 110px;
    height: 100px;
    right: 54px;
    top: -45px;
  }
}

.WorkWithUs-image {
  @extend %sticky-man-dimensions;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform-origin: right bottom;
  pointer-events: none;
  user-select: none;

  @media #{$tablet} {
    transform: rotate(0deg);
    transition: transform $peek-timing cubic-bezier(0.7, 0, 0.3, 1);
  }
}

// ========================
// ELEMENT STATES
// ========================

.WorkWithUs:hover,
.WorkWithUs:active,
.WorkWithUs:focus {
  .WorkWithUs-content {
    transform: translateX(-5%);
  }
}

.WorkWithUs.is-showing {
  opacity: 1;
  transform: translateY(52%) translateX(0);

  @media #{$tablet} {
    transform: translateY(-50%) translateX(0);

    .WorkWithUs-image {
      transform: rotate(-15deg);
    }
  }
}
