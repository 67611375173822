// ========================
// SpotlightCaseStudy
// ========================
// @note: goes here
// ========================
.SpotlightCaseStudy {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: rem(48px);

  @media #{$laptop} {
    margin-bottom: rem(64px);
  }
  @media #{$desktop} {
    margin-bottom: rem(80px);
  }
  @media #{$widescreen} {
    margin-bottom: rem(88px);
  }

  .Link {
    align-self: flex-start;
    margin-top: $v * 1.5;

    @media #{$tablet} {
      align-self: flex-end;
      margin-top: 0;
    }

    @media #{$desktop} {
      padding-top: $v;
    }
  }
}

// ========================
// ELEMENTS
// ========================

.SpotlightCaseStudy-banner {
  @extend %p;
  position: absolute;
  top: 0;
  color: $white;
  background-color: $flamingo;
  padding: 8px 12px;
}

.SpotlightCaseStudy-content {
  color: $white;
  position: absolute;
  top: rem(60px);
  left: $h;
  right: $h;

  @media #{$tablet} {
    top: 50%;
    transform: translate(0, -50%);
    width: 45%;

    > p {
      margin-top: $v / 2;
    }
  }

  p {
    color: $white; // workaround for not being able to put the class on
  }
}

.SpotlightCaseStudy-title {
  @extend %h4;
  font-family: 'Gilroy Medium', system-ui, sans-serif;
  margin-bottom: 0;
}
