// =============================
// YouTubeVideo
// =============================

.YouTubeVideo {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-top: $v;
  margin-bottom: $v;
}

.YouTubeVideo-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
