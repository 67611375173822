// ============
// VIDEO PLAYER
// ============

.VideoPlayer {
  position: relative;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba($black, 0.1);
}

.VideoPlayer:fullscreen {
  width: 100vw;
  height: 100vh;
  display: flex;
}

// ========================
// ELEMENTS
// ========================

.VideoPlayer-player {
  width: 100%;
  outline: none;
  display: block;
}

.VideoPlayer-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

// ========================
// OVERRIDES
// ========================

.VideoPlayer video::-webkit-media-text-track-display {
  font-family: $base-font-family;
  line-height: 1.35;
}

.VideoPlayer--controlsVisible video::-webkit-media-text-track-display {
  margin-top: -50px;

  @media #{$tablet} {
    margin-top: -100px;
  }
}
