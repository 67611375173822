// ============
// AUTOFILL
// ============

// To rid ourselves of the nasty yellow highlight

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: inset 0 0 0 rem(60px) $white;
  -webkit-text-fill-color: $midnight;
}
