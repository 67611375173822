// ==============
// COMPONENT INFO
// ==============

.ComponentInfo {
  position: relative;
  height: 0;
  width: 100%;
}

// ========================
// ELEMENTS
// ========================

.ComponentInfo-id {
  @extend %font-bold;
  position: absolute;
  font-size: 1em;
  left: 0;
  top: -50px;
  min-width: 100px;
  text-align: center;
  padding: 4px;
  background-color: pink;
  z-index: $z-site-banner;
  opacity: 0.6;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ComponentInfo-link {
  position: absolute;
  top: -50px;
  left: 150px;
  padding: 4px;
  color: $flamingo !important;
  text-decoration: underline !important;
}

.ComponentInfo-border {
  border-top: 1px solid pink;
  width: 200vw;
  transform: translateX(-50%);
  position: absolute;
  top: -1px;
}
