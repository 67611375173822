// ===========
// HERO TITLE
// ===========
$title-lines: 3;       // 1. how many lines of text?
$title-duration: 1000; // 2. how long is the animation? (milliseconds)
$title-delay: 400;     // 3. what's the delay between each line? (milliseconds)

// === <h2> wrapper ======
.HeroTitle {
  @extend %font-bold;
  font-size: rem(32px);
  margin: 0;
  transform: translate(0%, 33.333%) matrix(1, 0, 0, 1, 0, 0);
  will-change: opacity, transform;
  animation: revealTitle ($title-duration + ms) cubic-bezier(0.7, 0, 0.3, 1) forwards ($title-delay + ms);

  // The h2 is too large for mobile so make it slightly smaller
  // so the text isn't cut off
  @media #{$tablet} {
    font-size: rem(42px);
  }

  @media #{$desktop} {
    font-size: rem(56px);
  }
}

// ========================
// ELEMENTS
// ========================

// === Mask each line ======
.HeroTitle-mask {
  display: block;
  position: relative;
  text-align: start;
  overflow: hidden;
  height: rem(38px);

  @media #{$tablet} {
    height: rem(48px);
  }

  @media #{$laptop} {
    height: rem(56px);
  }

  @media #{$desktop} {
    height: rem(66px);
  }
}

// === Animate each line within the mask ======
.HeroTitle-line {
  display: inline-block;
  opacity: 0;
  transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
  will-change: opacity, transform;
  animation: revealLine ($title-duration + ms) cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

// =========
// Functions
// =========
@for $i from 1 through $title-lines {
  .HeroTitle-mask:nth-child(#{$i}) .HeroTitle-line {
    animation-delay: ($title-delay * $i) + ms;
  }
}

// ==========
// Animations
// ==========
@keyframes revealTitle {
  from { opacity: 0; transform: translate(0%, 33.333%) matrix(1, 0, 0, 1, 0, 0); }
  to { opacity: 1; transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}
@keyframes revealLine {
  from { opacity: 0; transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0); }
  to { opacity: 1; transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}
