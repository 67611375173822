// ========================
// HIGHLIGHT MODULE
// ========================
// @note: goes here
// ========================
.HighlightModule {
  background-color: $gentler-rhino;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;

  @media #{$tablet} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @media #{$laptop} {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media #{$desktop} {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .Container {
    position: relative;
    z-index: 2;
  }

  .SkeletonGrid {
    z-index: 1;
  }
}

// ========================
// ELEMENTS
// ========================
.HighlightModule-date {
  @include font(14px);

  margin-top: 0;
  margin-bottom: 8px;
}

.HighlightModule-heading {
  @extend %h4;

  margin: 0;

  @media #{$widescreen} {
    @include font(32px);
  }

  a {
    text-decoration: none;
    color: $midnight;
  }

  a:hover {
    color: $flamingo;
  }
}

.HighlightModule-description {
  @include font(14px);

  margin-top: 0;
  margin-bottom: rem(8px);

  @media #{$laptop} {
    margin-bottom: rem(16px);
  }

  @media #{$desktop} {
    @include font(20px);
  }
}
