.ServiceCategoryPartners-image {
  width: 100%;
  height: auto;
}

.ServiceCategoryPartners-title {
  width: 100%;
  text-align: center;
  height: initial;
  margin-top: 8px;
  margin-bottom: 32px;

  @media #{$tablet} {
    text-align: left;
  }

  @media #{$laptop} {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  @media #{$desktop} {
    margin-top: 48px;
    margin-bottom: 56px;
  }
}

.ServiceCategoryPartners-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;

  margin-bottom: 40px;
  
  @media #{$tablet} {
    margin-bottom: 88px;
  }

  @media #{$desktop} {
    margin-bottom: 96px;
  }
}

.ServiceCategoryPartners-list-item {
  width: 33%;
  margin-bottom: 0 !important;

  @media #{$tablet} {
    width: 20%;
  }
}

