// ====================================
// PEOPLE SECTION - US PAGE - LDR01-01a
// ====================================
$decoration-width-small: 244px;
$decoration-width-large: 304px;


// ========================
// ELEMENTS
// ========================

// .People-title {
//   margin-bottom: 0;
//   padding-bottom: $v;

//   @media #{$tablet} {
//     padding-right: $decoration-width-small;
//     padding-bottom: $v * 2;
//   }
//   @media #{$laptop} {
//     padding-right: $decoration-width-large;
//   }
// }

.People-title-decoration {
  display: none;
}

.People-list-item-image {
  @include fit-media(131, 238);
  width: 100%;
  height: rem(238px);

  @media #{$tablet} {
    @include fit-media(159, 247);
    height: rem(247px);
  }

  @media #{$laptop} {
    @include fit-media(212, 329);
    height: rem(329px);
  }

  @media #{$desktop} {
    @include fit-media(258, 400);
    height: rem(400px);
  }
}

.People-list-item-details {
  @media #{$desktop} {
    margin-bottom: $v * 2;
  }
}

.People-list-item-name {
  @extend %font-bold;
}

.People-list-item-role {
  margin: 0;
}

// ========================
// UI STATES
// ========================
.People-title.has-decoration {
  position: relative;
  margin-bottom: 0;
  padding-bottom: $v;

  @media #{$tablet} {
    padding-right: $decoration-width-small;
    padding-bottom: $v * 2;
  }
  @media #{$laptop} {
    padding-right: $decoration-width-large;
  }

  .People-title-decoration {
    @media #{$tablet} {
      display: block;
      position: absolute;
      bottom: -$v / 1.5;
      right: 0;
      z-index: 1;
      width: $decoration-width-small;
    }

    @media #{$laptop} {
      bottom: -$v;
      width: $decoration-width-large;
    }
  }
}
