.Strip-wrapper {
  position: relative;
}

.Strip-text {
  width: 100%;
  margin-top: $v / 2;

  @media #{$tablet} {
    position: absolute;
    top: 50%;
    left: #{(1 / 12) * 100%};
    transform: translate(0, -50%);
    margin-top: 0;
    width: 41%;
  }

  > p {
    margin-bottom: 0;
  }
}

// ==========
// VARIATIONS
// ==========

.Strip-wrapper--rightText {
  .Strip-text {
    right: #{(1 / 12) * 100%};
    left: auto;
  }
}

.Strip-wrapper--isLightTheme {
  @media #{$tablet} {
    color: $white;

    p {
      color: $white;
    }
  }
}
