// ==========
// CYCLE TEXT
// ==========

.CycleText {
  display: inline-block;
  white-space: nowrap;
}

// ========================
// ELEMENTS
// ========================

.CycleText-viewBox {
  display: block;
  position: relative;
}

.CycleText-line {
  display: block;
  will-change: opacity, transform;

  &.is-leaving {
    position: absolute;
    transform: translateY(0%);
    animation: slideOut 0.7s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  }

  &.is-current {
    position: absolute;
    transform: translateY(100%);
    animation: slideIn 0.7s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  }
}

// ==========
// MODIFIERS
// ==========
// Based on the font line-heights used in HeroTitle
.CycleText--hero {
  height: $v * 1.333333;

  @media #{$tablet} {
    height: $v * 2;
  }

  @media #{$laptop} {
    height: $v * 2.333333;
  }

  @media #{$desktop} {
    height: $v * 2.75;
  }
}

// ==========
// Animations
// ==========
@keyframes slideIn {
  from { opacity: 0; transform: translateY(100%); }
  to { opacity: 1; transform: translateY(0%); }
}
@keyframes slideOut {
  from { opacity: 1; transform: translateY(0%); }
  to { opacity: 0; transform: translateY(-100%); }
}
