// ========================
// QUOTE MODULE
// ========================
// @note: Full bleed quote displayed on the landing page
// ========================
.QuoteModule {
  background-color: $gentler-rhino;

  padding-top: rem(130px);
  padding-bottom: rem(130px);
}

// ========================
// ELEMENTS
// ========================
.QuoteModule-quoteIcon {
  background-image: url("/assets/images/interface/quotemarks.svg");
  display: block;
  width: 64px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
}

.QuoteModule-text {
  @extend %h4;

  margin-top: 16px;
  margin-bottom: 16px;

  @media #{$widescreen} {
    @include font(32px);
  }
}

.QuoteModule-citation {
  @extend %p;

  margin: 0;

  @media #{$widescreen} {
    @include font(20px);
  }
}

// // ========================
// // MODIFIERS
// // ========================
// .--modifier {}

// // ========================
// // UI STATES
// // ========================
// ..is-active {}