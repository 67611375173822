// ====================
// AWARDS - US PAGE
// ====================

// .Awards {}

// ========================
// ELEMENTS
// ========================
.Awards-stats,
.Awards-more {
  margin-top: $v * 1.5;
}

.Awards-stats {
  margin-bottom: $v;
}

.Awards-stats-grid,
.Awards-more-grid {
  list-style: none;
  padding: 0;
}

.Awards-stats {
  .SVG svg {
    max-width: 70px;
    max-height: 70px;

    @media #{$tablet} {
      max-width: 100px;
      max-height: 100px;
    }
  }
}

.Awards-stats-count {
  margin: 0;
}

.Awards-more-grid {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
