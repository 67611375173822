// =================================
// CASE STUDY OVERVIEW: ABT01, ABT02
// =================================

// .CaseStudy-overview {}


// ========================
// ELEMENTS
// ========================

.CaseStudy-overview-description {
  margin-bottom: $v * 2 / 3;

  @media #{$tablet} {
    margin-bottom: $v;
  }

  @media #{$desktop} {
    margin-bottom: $v * 4 / 3;
  }
}

.CaseStudy-overview-border {
  padding-top: $v * 2 / 3;
  border-top: 1px solid $border-grey;

  @media #{$tablet} {
    padding-top: $v;
  }

  @media #{$desktop} {
    padding-top: $v * 4 / 3;
  }
}

.CaseStudy-overview-item {
  margin-bottom: $v;

  &:last-child {
    margin-bottom: 0;
  }

  @media #{$tablet} {
    margin-bottom: $v / 2;
  }
}

.CaseStudy-overview-app {
  @media #{$laptop} {
    margin-bottom: $v / 2;
  }
}

.CaseStudy-overview-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Overview-tryout-content {
  width: rem(170px);

  .Button {
    min-width: 100%;
    padding: 0; // IE fix #25567
  }
}

.Overview-tryout-icon {
  img {
    width: 100%;
  }
}

.Link.Overview-siteLink {
  height: auto;
  font-size: rem(14px);

  @media #{$desktop} {
    font-size: rem(16px);
  }
}
