// ========================
// WHO WE ARE
// ========================
// @note: goes here
// ========================
.WhoWeAre {
  padding-bottom: 0.1px; // prevents .ModuleWrapper margin from collapsing together
}

// ========================
// ELEMENTS
// ========================
.WhoWeAre-content {
  position: relative;
  padding-top: $v;
  padding-bottom: $v * 3.5;
  padding-left: $h;
  padding-right: $h;

  @media #{$tablet} {
    padding-top: rem(32px);
    padding-bottom: rem(46px);
    padding-left: rem(62px);
    padding-right: rem(62px);
  }

  @media #{$laptop} {
    padding-top: rem(68px);
    padding-bottom: rem(82px);
    padding-left: rem(84px);
    padding-right: rem(84px);
  }

  @media #{$widescreen} {
    padding-top: rem(68px);
    padding-bottom: rem(124px);
    padding-left: rem(92px);
    padding-right: rem(92px);
  }
}

.WhoWeAre-content-background,
.WhoWeAre-content-CTA-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.WhoWeAre-content-wrapper,
.WhoWeAre-content-CTA-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ol li {
    display: flex;
    
    &:before {
      @extend .h4;
      color: $white;
    }
  }
}

.WhoWeAre-content-CTA {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 100%;

  max-width: calc(100% - 30px); // @warning: design number
  margin-top: rem(-50px); // @warning: design number
  padding-top: rem(50px);
  padding-bottom: rem(62px);
  padding-left: rem(30px);
  padding-right: rem(34px);

  @media #{$laptop} {
    max-width: rem(440px); // @warning: design number
    margin-top: rem(-60px); // @warning: design number
    padding-top: rem(40px);
    padding-bottom: rem(62px);
    padding-left: rem(80px);
    padding-right: rem(84px);
  }
}

.WhoWeAre-content-CTA-wrapper {
  text-align: center;
}

// ========================
// MODIFIERS
// ========================
// .WhoWeAre--modifier {}

// ========================
// UI STATES
// ========================
// .WhoWeAre.is-active {}
