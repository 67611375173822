// ==========================
// CASE STUDY NAVIGATION LINK
// ==========================

// .CaseStudy-navigation {}

// ========================
// ELEMENTS
// ========================

.CaseStudy-navigation {
  .SkewPoster-caption {
    padding: $v * 0.75;
    margin-left: auto;
    height: 185px;

    @media #{$tablet} {
      width: 200px;
      height: 240px;
    }

    @media #{$laptop} {
      padding: $v;
      width: 248px;
      height: 200px;
    }

    @media #{$desktop} {
      width: 324px;
      height: 235px;
    }

    @media #{$widescreen} {
      width: 405px;
      height: 294px;
    }
  }

  .SkewCaption-wrapper {
    margin: 0;
    position: relative;
  }

  .SkewCaption-text {
    position: absolute;
    top: 0;

    @media #{$tablet} {
      line-height: 1.4;
    }
  }

  .SkewCaption-link {
    position: absolute;
    bottom: 0;
  }

  .SkewCaption-link-title {
    @media #{$tablet-only} {
      font-size: 12px;
    }
  }
}

.CaseStudy-navigation-border {
  padding-top: $v;
  padding-bottom: $v / 2;
  border-top: 1px solid $border-grey;

  @media #{$laptop} {
    padding-bottom: $v;
  }
}

.CaseStudy-navigation-border--withMarginTop {
  margin-top: rem(40px);
}

.CaseStudy-navigation-relatedCaseStudy {
  @media #{$mobile-only} {
    margin-bottom: $v;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .SkewCaption-text {
    font-size: 20px;

    @media #{$desktop} {
      font-size: 24px;
    }
  }

  .SkewPoster-imageContainer {
    width: 248px;
    height: 426px;

    @media #{$tablet} {
      width: 191px;
      height: 330px;
    }

    @media #{$laptop} {
      width: 247px;
      height: 426px;
    }

    @media #{$desktop} {
      width: 315px;
      height: 545px;
    }

    @media #{$widescreen} {
      width: 432px;
      height: 749px;
    }
  }

  &:hover {
    .SkewCaption-link {
      color: $secondary;
    }

    .LinkArrow-head {
      stroke-dashoffset: 0;
      opacity: 1;
    }

    .LinkArrow g {
      stroke: $secondary;
    }

    .LinkArrow-body {
      stroke-dashoffset: 0;
      opacity: 1;
      animation: none;
    }
  }
}

.CaseStudy-navigation-link {
  font-size: 16px !important;
  margin-top: $v / 2 !important;

  @media #{$laptop} {
    font-size: 20px !important;
    margin-top: $v * 1.5 !important;
  }

  @media #{$desktop} {
    margin-top: $v * 2 !important;
  }

  span {
    margin-left: auto;
  }
}
