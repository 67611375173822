// ===================================================
// SKEW POSTER/MODULE, SKW01-01a, SKW01-01b, SKW01-01c
// ===================================================

// Height of the image at different breakpoints.
// A little magic, hence using a map+variables to ensure consistency
$skewImageHeights: (
  $tablet: 330px,
  $laptop: 470px,
  $desktop: 580px,
  $widescreen: 700px,
);

// Amount to shift the caption+overlay by vertically at different breakpoints
// This is needed so that the caption is vertically centered on the angled edge of the skewed image
$skewOverlayContainerOffsets: (
  $tablet: -18px,
  $laptop: -25px,
  $desktop: -32px,
  $widescreen: -40px,
);

.SkewPoster {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

// ========================
// ELEMENTS
// ========================

.SkewPoster-overlay {
  position: absolute;
  right: 0;
  bottom: -($v * 4);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.SkewPoster-overlay-image {
  max-height: 100%;
  min-width: 275px;// Need for IE

  @media #{$tablet} {
    max-width: rem(336px);
  }

  @media #{$laptop} {
    max-width: rem(448px);
  }
}

.SkewPoster-overlayContainer {
  @each $mediaQuery, $offset in $skewOverlayContainerOffsets {
    @media #{$mediaQuery} {
      margin-bottom: $offset;
    }
  }
}

.SkewPoster-imageContainer {
  position: relative;
  height: 0;

  // Force the image to have the correct aspect-ratio on small screens
  // Needed for IE, since it doesn't do nice automatic SVG sizing
  // See https://css-tricks.com/scale-svg/
  padding-bottom: 125%;

  @media #{$tablet} {
    height: auto;
    padding-bottom: 0;
  }

  @each $mediaQuery, $height in $skewImageHeights {
    @media #{$mediaQuery} {
      height: $height;
    }
  }
}

.SkewPoster-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media #{$tablet} {
    position: static;
  }
}

.SkewPoster-overlayContainer .Animator-lottie svg {
  @media #{$tablet} {
    width: auto !important;
    max-width: 100%;
  }
}

// ========================
// MODIFIERS
// ========================

$captionMobileHeight: 24px;
$captionTabletHeight: 48px;
$captionLaptopHeight: 72px;
$captionDesktopHeight: 96px;

// SKW01-01a
.SkewPoster--topCaption {
  &.has-overlay {
    margin-bottom: rem(120px);

    @media #{$tablet} {
      padding-bottom: $captionTabletHeight;
      margin-bottom: 0;
    }
    @media #{$laptop} {
      padding-bottom: $captionLaptopHeight;
    }
    @media #{$desktop} {
      padding-bottom: $captionDesktopHeight;
    }
  }

  .SkewPoster-overlay {
    @media #{$tablet} {
      top: -$captionTabletHeight;
      height: calc(100% + #{$captionTabletHeight});
    }

    @media #{$laptop} {
      top: -$captionLaptopHeight;
      height: calc(100% + #{$captionLaptopHeight});
    }

    @media #{$desktop} {
      top: -$captionDesktopHeight;
      height: calc(100% + #{$captionDesktopHeight});
    }
  }
}

// SKW01-01b
.SkewPoster--bottomCaption {
  padding-top: rem(-24px);

  &.has-overlay {
    margin-top: $v;

    @media #{$tablet} {
      margin-top: $v * 2;
    }
    @media #{$laptop} {
      margin-top: $v * 3;
    }
  }

  .SkewPoster-overlayContainer {
    margin-bottom: 0;

    @each $mediaQuery, $offset in $skewOverlayContainerOffsets {
      @media #{$mediaQuery} {
        margin-top: $offset;
      }
    }
  }

  .SkewPoster-overlay {
    top: -$captionMobileHeight;
    height: calc(100% + #{$captionMobileHeight});
    left: 0;

    @media #{$tablet} {
      top: -$captionTabletHeight;
      height: calc(100% + #{$captionTabletHeight});
      left: auto;
    }

    @media #{$laptop} {
      top: -$captionLaptopHeight;
      height: calc(100% + #{$captionLaptopHeight});
    }

    @media #{$desktop} {
      top: -$captionDesktopHeight;
      height: calc(100% + #{$captionDesktopHeight});
    }
  }
}

.SkewPoster--bottomCaption,
.SkewPoster--rightCaption {
  flex-direction: column;

  .SkewPoster-caption {
    top: auto;
    transform: none;
  }
}

// Right caption changes the skew direction of the main image,
// the skew direction of the caption, and is vertically centered at large sizes
// SKW01-01c
.SkewPoster--rightCaption {
  .SkewPoster-overlayContainer {
    text-align: right;
    margin-bottom: 0;

    @each $mediaQuery, $height in $skewImageHeights {
      @media #{$mediaQuery} {
        margin-top: -$height / 2;
        min-height: $height / 2; // counter the negative margin to keep things below positioned sensibly
      }
    }
  }

  .SkewPoster-caption {
    position: relative;

    &::before {
      transform: scaleX(-1);
    }
  }

  .SkewPoster-overlay {
    position: static;
    margin-top: rem(-20px); // moving up the overlay so it's aligned with the corner of the caption shape
    height: rem(167px); // arbitrary fixed height for the image, to force it to both be a sensible size and allow it to flow off the right edge of the page

    @media #{$tablet} {
      height: auto;
    }
  }

  .SkewPoster-overlay-image {
    z-index: 1;

    @media #{$mobile-only} {
      width: auto;
      max-width: none;
      flex-shrink: 0;
    }
  }
}

.SkewPoster-overlay.is-bottom {
  @media #{$mobile-only} {
    max-width: rem(366px);
  }
}
