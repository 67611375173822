// ========================
// BUTTON SETTINGS
// ========================


// ==========================================
// STEP 1: Set up common variables
// ==========================================
$button-height:               56px;
$button-min-width:            180px;
$button-border-width:         1px;
$button-border-radius:        $button-height / 2;
$button-disabled-opacity:     0.4;
$button-horizontal-padding:   $h * 2;
$button-transition-speed:     $timing;

// ==========================================
// STEP 2: Define button modifiers and states
// ==========================================
/// @type Map
/// ============
/// @prop {String} modifier - button classname modifier
/// @prop {String} state - ui state e.g focus
/// @prop {Array} colours - comma separated colour values
/// ============
/// <modifier>: (
///   <state>: (<text>, <background>, <border>)
/// )
/// ============
/// Output classes:
/// .Button--primary {...}
/// .Button--secondaryLight {...}
/// .Button--secondaryDark {...}
/// ============
$button-states: (
  primary: (
    default: ($white, $flamingo, null),
    focus:   ($white, $flamboyant-flamingo, null),
    hover:   ($white, $flamboyant-flamingo, null),
    active:  ($white, $flamboyant-flamingo, null),
    loading: ($white, $flamboyant-flamingo, null)
  ),
  secondaryLight: (
    default: ($flamingo, transparent, $flamingo),
    focus:   ($flamboyant-flamingo, transparent, $flamboyant-flamingo),
    hover:   ($flamboyant-flamingo, transparent, $flamboyant-flamingo),
    active:  ($flamboyant-flamingo, transparent, $flamboyant-flamingo),
    loading: ($flamboyant-flamingo, transparent, $flamboyant-flamingo)
  ),
  secondaryDark: (
    default: ($white, transparent, $white),
    focus:   ($white, rgba($white, 0.15), $white),
    hover:   ($white, rgba($white, 0.15), $white),
    active:  ($white, rgba($white, 0.15), $white),
    loading: ($white, transparent, $white),
  ),
  footer: (
    default: ($midnight, $blue-chalk, null),
    focus:   ($midnight, $blue-chalk, null),
    hover:   ($midnight, $blue-chalk, null),
    active:  ($midnight, $blue-chalk, null),
    loading: ($midnight, $blue-chalk, null),
  )
);
