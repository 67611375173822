// ==============
// MODULE_WRAPPER
// ==============

// These classes are used to space out the each CaseStudy and Us modules based on the global spacing designs

.ModuleWrapper {
  margin-bottom: rem(80px);

  @media #{$tablet} {
    margin-bottom: rem(100px);
  }

  @media #{$desktop} {
    margin-bottom: rem(120px);
  }
}

// ========================
// MODIFIERS
// ========================
.ModuleWrapper--top {
  padding-top: rem(40px);

  @media #{$tablet} {
    margin-bottom: rem(80px);
  }
}
