// ============
// $ICONS
// ============

// Usage
// CSS:
// .my-element:before {
//   @extend %icon !optional;
//   @extend .icon-close !optional;
//   ...
// }
// HTML:
// <i class="icon icon-close"></i>

@font-face {
  font-family: 'icons';
  src: url('/assets/icons/alphero_glyphs.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// NOTE: Extend a %placeholder selector here, to avoid having .icon @extended every time we add styles to a .icon within a component
%icon {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.icon { @extend %icon !optional; }

/* stylelint-disable rule-empty-line-before, selector-pseudo-element-colon-notation */
// Icomoon .icon- content values ============

.icon-quote:before {
  content: "\e90a";
}
.icon-attach:before {
  content: "\e900";
}
.icon-clear:before {
  content: "\e901";
}
.icon-error:before {
  content: "\e906";
}
.icon-chevron:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-arrow-forwards:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e90d";
}
.icon-arrow-back:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e909";
}

/* stylelint-enable rule-empty-line-before, selector-pseudo-element-colon-notation */
