// =================================
// CASE STUDY BACKGROUND: BKG01-01a
// =================================

.CaseStudy-background {
  @media #{$tablet} {
    position: relative;
    display: flex;
    align-items: center;
  }

  @media #{$laptop} {
    margin-top: $v * 4;
    margin-bottom: $v * 4;
  }

  @media #{$desktop} {
    margin-top: $v * 5;
    margin-bottom: $v * 5;
  }
}

.CaseStudy-background-shape {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: $v / 2;
    height: 100%;
    width: calc(100% - #{$v}); // subtracting to account for skew making it wider
    z-index: -1;
    transform: skew(1deg);

    @media #{$tablet} {
      left: $v / 2;
      width: calc(100% - #{$v});

      transform: skew(4deg);
    }

    @media #{$laptop} {
      left: $v;
      width: calc(100% - #{$v * 2});

      transform: skew(4deg);
    }
  }
}

.CaseStudy-background-content {
  padding: {
    top: $v;
    right: $v;
    bottom: rem(130px);
    left: $v;
  }

  p:last-of-type {
    margin-bottom: $v / 2;
  }

  @media #{$tablet} {
    padding: {
      top: $v * 1.5;
      right: $h * 0.5;
      bottom: $v * 1.5;
      left:  $h * 2;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media #{$laptop} {
    padding: {
      top: $v * 3;
      right: $h / 2;
      bottom: $v * 3;
      left:  $h * 4;
    }
  }
}

.CaseStudy-background-heading {
  color: $flamingo;
}


.CaseStudy-background-overlay {
  margin-top: rem(-114px);
  text-align: center;

  @media #{$mobile-only} {
    .ImageWrapper {
      margin: 0 auto;
      max-width: rem(366px);
      width: 100%;
    }
  }

  @media #{$tablet} {
    width: rem(274px);
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.CaseStudy-background-overlay--topAlignment {
  @media #{$tablet} {
    top: -50px;
    transform: none;
  }
  @media #{$laptop} {
    top: -70px;
  }
}


.CaseStudy-background-link {
  &.Link { // specificity needed to override other styles :(
    height: auto;
    margin-top: $v / 2;
  }


  @media #{$tablet} {
    padding-bottom: 0;
  }

  span {
    font-size: 14px;
  }
}

// ========================
// MODIFIERS
// ========================

.CaseStudy-background-heading--white,
.CaseStudy-background-text--white,
a.CaseStudy-background-link--white {
  color: $white;
}

a.CaseStudy-background-link--midnight {
  &:hover {
    color: $white;
  }
}

.CaseStudy-background-graphic--midnight {

  &::before {
    background-color: $midnight;
  }
}

.CaseStudy-background-graphic--flamingo {

  &::before {
    background-color: $flamingo;
  }
}

.CaseStudy-background-graphic--sky {

  &::before {
    background-image: $sky;
  }
}

.CaseStudy-background-graphic--sunset {

  &::before {
    background-image: $sunset;
  }
}

.CaseStudy-background-link--white .LinkArrow g {
  stroke: $white !important;
}

.CaseStudy-background-link--white:hover .LinkArrow g {
  stroke: $flamingo !important;
}


.CaseStudy-background-link--midnight .LinkArrow g {
  stroke: $midnight !important;
}

.CaseStudy-background-link--midnight:hover .LinkArrow g {
  stroke: $white !important;
}