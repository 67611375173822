// ========================
// CASE STUDY CTA
// ========================

.CaseStudyCTA {
  color: $white;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: url('/assets/images/img-couldbeyou-small.svg') 0 0 no-repeat;
  background-size: 100% 100%;
  height: 100%;
  padding: {
    top: $v;
    right: $h * 0.5;
    bottom: $v;
    left: $h * 0.5;
  }

  @media #{$tablet} {
    width: rem(217px);

    background-image: url('/assets/images/img-couldbeyou-1-column.svg');

    padding: {
      top: $v;
      right: $h * 1.5;
      bottom: $v;
      left: $h * 1.5;
    }
  }

  @media #{$desktop} {
    padding: {
      top: $v;
      right: $h * 2.15;
      bottom: $v;
      left: $h * 2.15;
    }
  }
}

// ========================
// ELEMENTS
// ========================

.CaseStudyCTA-content {
  text-align: center;
  width: 100%;

  .Button {
    min-width: 0;
    width: 100%;
    padding: 0;
  }
}

.CaseStudyCTA-text {
  text-align: center;

  // Yes, we're breaking from the standard typography styling here. Necessary because there's such limited space.
  @include font(14px);

  @media #{$tablet} {
    @include font(16px);
  }

  @media #{$desktop} {
    @include font(20px);
  }
}

.CaseStudyCTA--two,
.CaseStudyCTA--three {
  padding: {
    top: $v;
    right: $h;
    bottom: $v;
    left: $h;
  }

  .Button {
    width: 100%;

    @media #{$tablet} {
      width: auto;

      padding: {
        top: 0;
        right: $h;
        bottom: 0;
        left: $h;
      }
    }

    @media #{$desktop} {
      padding: {
        top: 0;
        right: $button-horizontal-padding;
        bottom: 0;
        left: $button-horizontal-padding;
      }
    }
  }
}

.CaseStudyCTA--two {
  background-image: url('/assets/images/img-couldbeyou-2-columns.svg');
}

.CaseStudyCTA--three {
  background-image: url('/assets/images/img-couldbeyou-3-columns.svg');
}
