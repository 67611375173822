// ========================
// HERO BANNER MODULE
// ========================
// @note: goes here
// ========================
$mask-height-sm: auto;
$mask-height-md: 348px;
$mask-height-lg: 471px;
$mask-height-xl: 594px;
$mask-height-xxl: 725px;

@mixin heroHeight($prefix: null) {
  #{$prefix}height: #{$mask-height-sm};
  @media #{$tablet} { #{$prefix}height: #{$mask-height-md}; }
  @media #{$laptop} { #{$prefix}height: #{$mask-height-lg}; }
  @media #{$desktop} { #{$prefix}height: #{$mask-height-xl}; }
  @media #{$widescreen} { #{$prefix}height: #{$mask-height-xxl}; }
}

.HeroBanner {
  @include heroHeight();

  position: relative;
}

// ========================
// ELEMENTS
// ========================
.HeroBanner-wrapper {
  position: relative;
  margin-bottom: -$v;

  @media #{$tablet} {
    height: 100%;
  }
}

.HeroBanner-background {
  left: -$h / 2;
  pointer-events: none;
  position: relative;
  user-select: none;
  height: 100%;
  width: calc(100% + #{$h});

  @media #{$tablet} {
    bottom: initial;
    height: 100%;
  }
}

.HeroBanner-background-mask,
.HeroBanner-background-asset {
  @media #{$tablet} {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.HeroBanner-background-mask {
  overflow: hidden;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.HeroBanner-background-asset {
  z-index: 0;
  padding: $v / 2 $h / 2;
}

.HeroBanner-content {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 100%;

  @media #{$tablet} {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.HeroBanner-content-words {
  margin-bottom: $v;

  p {
    color: $midnight;
  }

  @media #{$tablet} { padding: 0 $h; margin-bottom: 0; }
  @media #{$desktop} { padding: 0 $h * 2; }
}

.HeroBanner-content-image {
  width: auto;
  height: 100%;

  @media #{$tablet} {
    width: 100%;
  }
}

.HeroBanner-content-image {
  .ImageWrapper {
    display: flex;
    justify-content: center;
  }

  .ImageWrapper-img {
    width: auto;

    @media #{$tablet} {
      width: 100%;
    }
  }
}

// ========================
// MODIFIERS
// ========================
.HeroBanner--light {
  .HeroBanner-content-words {
    @media #{$tablet} {
      p{ color: $white; }
    }
  }
}

.HeroBanner--backgroundLoading {
  .HeroBanner-content-words {
    color: $midnight;
  }
}
