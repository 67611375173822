// =========================
// FANCY HORIZONTAL RULE STYLES
// =========================
// @note: must use a horizontal rule tag
//
// @usage:
//   <hr className="FancyRule" aria-hidden />
//   <hr className="FancyRule FancyRule--dots" aria-hidden />
// =========================

hr.FancyRule {
  height: rem(10px);
  width: rem(88px);
  margin: rem(5px) 0 $v;
  background: url("/assets/images/interface/hr-moving.svg") -1px 0 repeat-x;
  border-radius: rem(50px);
  text-align: left;
}


// ========================
// MODIFIERS
// ========================
hr.FancyRule--video {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

hr.FancyRule--static {
  height: rem(10px);
  width: rem(89px);
  background: url("/assets/images/interface/hr-static.svg");
}

hr.FancyRule--drawn {
  height: rem(15px);
  width: rem(85px);
  margin: rem(8px) 0 $v;
  background: url("/assets/images/interface/hr-drawn.svg");
}

hr.FancyRule--zigZag {
  width: rem(89px);
  background: url("/assets/images/interface/hr-zigzag.svg");
}

hr.FancyRule--dots {
  width: rem(89px);
  background: url("/assets/images/interface/hr-dots.svg");
}
