.CategoryHeader-textWrapper {
  height: 0;
  position: relative;
}

.CategoryHeader {
  display: flex;
  position: relative;

  @media #{$mobile-only} {
    margin-top: 24px;
  }

  @media #{$tablet} {
    height: auto;
    flex-direction: column;
    // margin-bottom: calc(-84px - 24px);
  }

  @media #{$desktop} {
    // margin-bottom: calc(-88px - 40px);
  }
}

.CategoryHeader-subtitle {
  height: auto;
  max-height: 50px;
  transition: 1s ease;
  font-family: $base-font-family;
  margin-top: 0;
  margin-bottom: 0;
  color: $rhino;

  @media #{$mobile-only} {
    font-size: 18px;
  }

  @media #{$tablet} {
    margin-top: 16px;
  }

  @media #{$laptop} {
    margin-top: 24px;
  }

  @media #{$desktop} {
    margin-top: 32px;
  }

  @media #{$widescreen} {
    margin-top: 40px;
  }
}

.CategoryHeader-title-underline {
  // margin-top: 16px !important;
  margin-top: 12px !important;
  margin-bottom: 0 !important;
  width: 100%;

  @media #{$mobile-only} {
    &.FancyRule {
      width: rem(47px);
    }
  }

  @media #{$tablet} {
    margin-top: 8px !important;
  }
}

.CategoryHeader-title-underline-container {
  transition: 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;

  @media #{$tablet} {
    width: 100%;
    padding-left: 48px;
    padding-right: 48px;
  }

  @media #{$laptop} {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media #{$desktop} {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media #{$widescreen} {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.CategoryHeader-titlesArea {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 5%;
  margin-top: 12px;

  @media #{$tablet} {
    margin-top: 16px;
  }

  @media #{$laptop} {
    margin-top: 24px;
  }
}

.CategoryHeader-text {
  height: auto;
  background-color: white;
  transition: all 0.5s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$tablet} {
    transform: translateY(-62px);
    height: 128px;
  }

  @media #{$laptop} {
    transform: translateY(-80px);
    height: 174px;
  }

  @media #{$desktop} {
    transform: translateY(-102px);
    height: 214px;
  }

  @media #{$widescreen} {
    transform: translateY(-128px);
    height: 214px;
  }
}

.CategoryHeader-text-sticky {
  @media #{$tablet} {
    position: fixed !important;
    bottom: initial;
    top: 0 !important;
    z-index: 1000;
  }
}

.CategoryHeader-title {
  display: none;
  position: relative;
  font-size: 28px;
  line-height: 32px;
  font-family: $base-font-family;
  text-decoration: none;
  color: #9290b7;
  margin-top: 0;
  margin-bottom: 0;

  @media #{$tablet} {
    display: block;
    font-size: 24px;
    line-height: 32px;
  }

  @media #{$laptop} {
    font-size: 32px;
    line-height: 40px;
  }

  @media #{$desktop} {
    font-size: 40px;
    line-height: 48px;
  }

  &:hover {
    cursor: pointer;
  }
}

.CategoryHeader-title--selected {
  color: $primary !important;
  opacity: 1;
  display: block;
}

.CategoryHeader-imageArea {
  @include fit-media(1280px, 512px);
  display: none;
  @media #{$tablet} {
    display: block;
  }
}
