// ========================
// IMAGE COLLAGE
// ========================
// @note: Insanity ensues
// ========================

$collage-row-one-height: 325px;
$collage-row-one-height-xxl: 420px;
$collage-row-two-height: 264px;
$collage-row-two-height-xxl: 350px;
$collage-item-spacing: $v / 2;

$collage-1of12: (1 / 12) * 100%;
$collage-2of12: (2 / 12) * 100%;
$collage-3of12: (3 / 12) * 100%;
$collage-4of12: (4 / 12) * 100%;
$collage-5of12: (5 / 12) * 100%;
$collage-6of12: (6 / 12) * 100%;
$collage-7of12: (7 / 12) * 100%;
$collage-8of12: (8 / 12) * 100%;

// @note: I think this could be handled more elegantly, but not sure if it requires a lot more rework
$image-decoration-width-tablet: 134px;
$image-decoration-height-tablet: 390px;
$image-decoration-spacing-tablet: $v * 3.5;


// ========================
// ELEMENTS
// ========================

.ImageCollage {
  @media #{$mobile-only} {
    overflow-x: hidden;
  }
}

.ImageCollage-collage {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media #{$mobile-only} {
    overflow: hidden;
    margin-left: -$container-gutter * 2;
    margin-right: -$container-gutter * 2;
  }
}

.ImageCollage-collage-image {
  position: relative;
  margin-bottom: $v / 2;
  margin-right: -$v / 2;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.ImageCollage-collage-decoration {
  display: none;
  z-index: -1;

  @media #{$tablet} {
    display: block;
    position: absolute;
    top: 0;
    right: $image-decoration-spacing-tablet;
    height: $image-decoration-height-tablet;
    width: $image-decoration-width-tablet;
    padding-top: $h;
  }

  @media #{$laptop} {
    right: $v;
  }

  @media #{$desktop} {
    width: $collage-2of12;
    height: $collage-row-one-height;
    right: $collage-item-spacing;
  }

  @media #{$widescreen} {
    height: $collage-row-one-height-xxl;
    right: $collage-item-spacing;
  }
}


// ========================
// MODIFIERS
// ========================

.ImageCollage-collage-image {
  // === Set the heights of the collage "rows" (mobile) ===
  // First item
  &[data-child="1"] { height: $collage-row-one-height; }
  // Other items
  &[data-child="2"],
  &[data-child="3"],
  &[data-child="4"],
  &[data-child="5"] { height: $collage-row-two-height; } // the rest

  // === Set the percentage widths of each item (mobile) ===
  &[data-child="1"] { width: 100%; }
  &[data-child="2"] { width: $collage-5of12; }
  &[data-child="3"] { width: calc(#{$collage-7of12} + #{$collage-item-spacing}); }
  // Hide the last two until desktop
  &[data-child="4"],
  &[data-child="5"] { display: none; }


  @media #{$tablet} {
    // === Set the percentage widths of each item (tablet) ===
    &[data-child="1"] { width: $collage-8of12; }
    &[data-child="2"] { margin-left: $collage-1of12; }
    &[data-child="3"] { width: calc(#{$collage-6of12} + #{$collage-item-spacing}); }
  }


  @media #{$laptop} {
    // === Set the heights of the collage "rows" (desktop) ===
    // First two items
    &[data-child="1"],
    &[data-child="2"] { height: $collage-row-one-height; }
    // Other items
    &[data-child="3"],
    &[data-child="4"],
    &[data-child="5"] { height: $collage-row-two-height; }

    // === Set the percentage widths of each item (desktop) ===
    &[data-child="1"] { width: $collage-6of12; }
    &[data-child="2"] { width: $collage-4of12; margin: 0; }
    &[data-child="3"] { width: $collage-5of12; margin-left: $collage-1of12; }
    // Show the last two that got hidden at mobile
    &[data-child="4"],
    &[data-child="5"] { width: calc(#{$collage-3of12} + #{$collage-item-spacing}); display: block; }
  }

  @media #{$widescreen} {
    // === Set the heights of the collage "rows" (widescreen) ===
    // First two items
    &[data-child="1"],
    &[data-child="2"] { height: $collage-row-one-height-xxl; }
    // Other items
    &[data-child="3"],
    &[data-child="4"],
    &[data-child="5"] { height: $collage-row-two-height-xxl; }
  }
}

