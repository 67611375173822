// ==================
// FEATURED ITEM
// ==================

// Used on the Home page

.FeaturedItem {
  text-align: left;
  position: relative;
  text-decoration: none;
  color: $text;
  box-sizing: border-box;
  display: block;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;

  @media #{$tablet} {
    height: rem(442px);
  }

  @media #{$desktop} {
    height: rem(555px);
  }

  @media #{$widescreen} {
    height: rem(665px);
  }
}

// ========================
// ELEMENTS
// ========================

.FeaturedItem-grid {
  height: 100%;
}

.FeaturedItem-graphic {
  min-width: rem(290px);
  height: rem(575px);
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media #{$tablet} {
    height: 100%;
    display: block;
  }
}

.FeaturedItem-content {
  z-index: 3;
  width: rem(258px);
  padding-top: $v * 2;
  padding-right: $h;
  padding-left: $h / 2;

  @media #{$tablet} {
    width: rem(300px);
    text-align: left;
    padding-top: $v * 6;
    padding-right: $h;
    padding-left: $h * 3;
  }

  @media #{$laptop} {
    width: rem(350px);
    padding-left: $h * 3.5;
  }

  @media #{$desktop} {
    width: rem(400px);
    padding-left: $h * 4;
  }

  @media #{$widescreen} {
    width: rem(658px);
    padding-top: $v * 10;
    padding-left: $h * 5.5;
  }
}

.FeaturedItem-title {
  @extend %font-medium;
  @include font(24px);
  margin: {
    top: $v;
    bottom: $v;
  }

  @media #{$tablet} {
    margin-top: 0;
  }

  @media #{$desktop} {
    @include font(32px);
  }
}

.FeaturedItem-subtitle {
  padding-right: $h * 2;

  margin: {
    top: 0;
    bottom: 0;
  }
}

// ==========================
// STATES
// ==========================

.FeaturedItem:hover,
.FeaturedItem:active,
.FeaturedItem:focus {
  color: $text;
}

// ==========================
// MODIFIERS
// ==========================

// === Dark Gradient, Light Text
.FeaturedItem--light {
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}
