// =============================
// CASE STUDY HERO: HEA01, HEA02
// =============================

.CaseStudy-hero {
  position: relative;
}


// ========================
// ELEMENTS
// ========================

.CaseStudy-hero-content {
  @media #{$tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.CaseStudy-hero-content-text {
  margin-bottom: rem(24px);

  @media #{$tablet} {
    margin-bottom: 0;
  }
}

.CaseStudy-hero-graphic {
  @media #{$tablet} { height: rem(330px); }
  @media #{$laptop} { height: rem(452px); }
  @media #{$desktop} { height: rem(580px); }
  @media #{$widescreen} { height: rem(632px); }
}

// ========================
// UI STATES
// ========================

.is-loading {
  .CaseStudy-hero-graphic {
    @extend %loading-pulse;
    min-height: 300px;
  }

  .CaseStudy-hero-content-text h2,
  .CaseStudy-hero-content-text p {
    @include loading-text();
  }

  .CaseStudy-hero-content-text h2 {
    @include loading-text('Loading page');
  }
}
