// ==========================
// FEATURED CASE STUDIES LIST
// ==========================

// .Featured {}

// ========================
// ELEMENTS
// ========================

.Featured-caseStudies {
  list-style: none;
  padding: 0;
  margin: 0;

  @media #{$tablet} {
    margin-bottom: $v;
  }
}

.Featured-caseStudies-item {
  text-align: right; // For the "See all work" link

  // THIS IS A HACK. It forces Chrome to render crisp text, which it wouldn't otherwise.
  // Specifically, having `perspective` on the parent causes the text in this element to render blurry at some screen sizes
  // after hovering on the image.
  transform: scale(1.0001);

  &:not(:last-child) {
    margin-bottom: $v * 3;
    @media #{$tablet} {
      margin-bottom: 0;
    }
  }
}

.Featured-caseStudies-item-animator {
  margin-bottom: rem(72px); // Rounded to nearest 24px

  @media #{$tablet} {
    margin-bottom: rem(24px); // Rounded to nearest 24px
  }
}

.Featured-caseStudies-item-tilt {
  // Needed to prevent Firefox from clipping the tilt transform
  transform-style: preserve-3d;
  // Needed to prevent jagged aliasing in firefox. 
  outline: 1px solid transparent;
}
