// ========================
// FEATURED MODULES
// ========================
// @note: For featured tags and things on the Ideas Factory layout
// ========================

.FeaturedModule {
  background-color: $gentler-rhino;

  .IdeasFactoryTile {
    background-color: $white;
  }
}

// ========================
// ELEMENTS
// ========================
.FeaturedModule-content {
  padding-top: rem(28px);
  padding-bottom: rem(28px);

  @media #{$laptop} {
    padding-top: rem(32px);
    padding-bottom: rem(32px);
  }
  @media #{$desktop} {
    padding-top: rem(48px);
    padding-bottom: rem(48px);
  }
}

.FeaturedModule-content-heading {
  @extend %h4;
  margin: 0;

  @media #{$widescreen} {
    @include font(32px);
  }
}

.FeaturedModule-content-description {
  @include font(14px);
  margin-top: $v / 1.5;
  margin-bottom: 0;

  @media #{$desktop} {
    @include font(20px);
  }
}

// ========================
// MODIFIERS
// ========================
.FeaturedModule--onlyPosts {
  background-color: $white;

  .IdeasFactoryTile {
    background-color: $gentler-rhino;
  }
}

.FeaturedModule--categories {
  .FeaturedModule-content-heading {
    @include font(20px);

    @media #{$laptop} {
      @include font(24px);
    }
  }
}
