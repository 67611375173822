// =========================
// LINK ARROW – BASE SETTINGS
// =========================

// These are the base settings for link arrows around the site

$arrowhead-length: 4.243;
$arrowline-length: 37px;

// <svg>
.LinkArrow {
  width: rem(60px);
  height: 12.3px; // Very hardcoded, needed to keep IE and Edge from sizing the SVG weirdly
  animation: arrow-exit 0.3s forwards ease;
  pointer-events: none;
}

%arrow-hover {
  .LinkArrow {
    animation: arrow-enter 0.3s forwards ease;

    .LinkArrow-body { animation: line-enter 0.3s forwards ease; }
    .LinkArrow-head { stroke-dashoffset: 0px; opacity: 1; }
  }
}

// ========================
// ELEMENTS
// ========================

.LinkArrow-body {
  stroke-dasharray: $arrowline-length $arrowline-length + 2; // we add 2 to the empty dash so IE and Edge don't show 1px of the solid dash either side
  opacity: 0;
  animation: line-exit 0.3s ease;
}

.LinkArrow-head {
  stroke-dasharray: $arrowhead-length;
  stroke-dashoffset: $arrowhead-length;
  opacity: 0;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}

// ============
// ANIMATIONS
// ============

@keyframes arrow-enter {
  from { transform: translateX(-50%); }
  to { transform: translateX(0%); }
}

@keyframes arrow-exit {
  from { transform: translateX(0%); }
  to { transform: translateX(50%); }
}

@keyframes line-enter {
  from {
    opacity: 0.5;
    stroke-dashoffset: $arrowline-length + 1; // we add 1 to compensate for the 2px we added earlier
  }

  to { stroke-dashoffset: 0px; opacity: 1; }
}

@keyframes line-exit {
  from { stroke-dashoffset: 0px; opacity: 1; }
  to { stroke-dashoffset: -$arrowline-length - 1; opacity: 0.5; }
}
