// ==============================
// CASE STUDY IMAGE: IMG01, IMG02
// ==============================

// .CaseStudy-image {
//   margin-top: rem(-64px);

//   @media #{$tablet} {
//     margin-top: 0;
//   }
// }

// .CaseStudy-image--no-pull {
//   margin-top: 0;
// }

.CaseStudy-image-wrapper {
  width: 100%;
  @include fit-media(23,16);
}
