// ========================
// SKILL HEADER
// ========================

// Approx height of the skill module at different breakpoints
// Needed to make sticky positioning things work nicely
$skillModuleHeights: (
  $tablet: 350px,
  $laptop: 385px,
  $desktop: 470px
);

.SkillsHeader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// ========================
// ELEMENTS
// ========================

.SkillsHeader-inner {
  position: relative;
  // affects when the header starts sticking
  height: 0;
  // Forcing aspect ratio to match skill module image+text. 35px is the height of the text
  padding-top: calc(#{(9 / 16) * 100%} + 35px);
  transform: translateY(-55px);

  @media #{$mobile-only} {
    width: 100%;

    > * {
      position: absolute;
      top: 0;
    }
  }

  @media #{$tablet} {
    padding-top: 0;
    height: auto;
    transform: translateY(-65px);
  }

  @media #{$desktop} {
    transform: translateY(-85px);
  }

  // For sizes above mobile, match the static height of a skill module
  // Needed to make the header stop sticking at the correct point
  @each $mediaQuery, $height in $skillModuleHeights {
    @media #{$mediaQuery} {
      height: $height;
    }
  }
}

@supports (position: sticky) {
  @media not all and (prefers-reduced-motion: reduce) {
    .SkillsHeader--sticky {
      display: block;

      .SkillsHeader-inner {
        position: sticky;
        top: 25%;
      }
    }

    .SkillsHeader--static svg {
      // From .u-hiddenVisually - duplicated here since we can't @extend it from inside a @supports block
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      height: 1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
    }
  }
}

.SkillsHeader--static {
  z-index: 1;
}

.SkillsHeader-shape {
  position: absolute;
  width: 100%;
  // Shape is shifted slightly off-screen on mobile by a non-specific amount
  left: -85px;
  transform: translateZ(0); // Hack to force Chrome to render the entire SVG, like it should

  @media #{$tablet} {
    left: 0;
    width: 50%;
  }
}

// specificity needed to override IE hack in naturalize.scss
svg.SkillsHeader-shape {
  overflow: visible;
}

.SkillsHeader-title {
  @extend %font-bold;
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  // Display font size + magic 10px
  height: rem(66px);

  @media #{$laptop} {
    height: rem(74px);
  }

  @media #{$desktop} {
    height: rem(98px);
  }
}

// This could be moved to its own file but it's only present in SkillModule at this stage
.TextSlider {
  transition: transform 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  display: block;
}

.TextSlider-item {
  display: block;
}
