// ========================
// EMERGENCY BANNER
// ========================
.EmergencyBanner {
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding-top: $v;
  padding-bottom: $v;
  padding-left: 16px;
  padding-right: 16px;

  position: relative;
  z-index: $z-notification;
  color: #ffffff;
  background-color: $tsunami-green;

  @media #{$tablet} {
    align-items: center;

    padding-left: $h;
    padding-right: $h;
  }

  .icon-close {
    cursor: pointer;

    @media #{$mobile-only} {
      margin-top: -8px;
      font-size: 13px;
    }

    // Yes, the font-size is used to edit the size of the icon.
    // God knows why...
    @media #{$tablet} {
      font-size: 16px;
    }

    @media #{$laptop} {
      font-size: 16px;
    }

    @media #{$desktop} {
      font-size: 16px;
    }

    @media #{$widescreen} {
      font-size: 20px;
    }
  }

  .EmergencyBanner-text {
    font-size: 14px;
    line-height: 20px;
    padding-right: 16px;
    padding-top: 6px;

    @media #{$tablet} {
      padding-right: 0;
      padding-top: 0;
    }

    @media #{$desktop} {
      font-size: 16px;
    }
    @media #{$widescreen} {
      font-size: 20px;
    }
  }

  .EmergencyBanner-mailto {
    color: #ffffff;
    text-decoration: underline;
  }

  .EmergencyBanner-message {
    display: flex;
    align-items: flex-start;

    @media #{$tablet} {
      align-items: center;
    }
  }

  .EmergencyBanner-close {
    /* Override button styles */
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    color: white;

    position: absolute;
    top: 16px;
    right: 32px;

    @media #{$tablet} {
      position: relative;
      top: 0;
      right: 16px;
    }
  }

  .EmergencyBanner-icon {
    width: fit-content;
    height: fit-content;
    svg {
      display: block;
      width: 25px;
      height: 35px;

      @media #{$tablet} {
        width: 41px;
        height: 52px;
      }
    }
  }
}

// ========================
// ELEMENTS
// ========================
// .-part {}
// .-anotherPart {}

// ========================
// MODIFIERS
// ========================
// .--modifier {}

// ========================
// UI STATES
// ========================
// ..is-active {}