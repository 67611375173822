.MobileCategoryHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media #{$tablet} {
    display: none;
  }
}

.MobileCategoryHeader-scrollContainer {
  position: relative;
  width: 100%;
  max-width: 70vw;

  .swiper-slide {
    width: 33% !important;
  }

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    z-index: 100;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }

  &::after {
    content: '';
    z-index: 100;
    width: 80px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-image: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }
}

.MobileCategoryHeader-fancyRule {
  margin-bottom: 0 !important;
}

.MobileCategoryHeader-subtitle {
  margin-bottom: 0;
}

.MobileCategoryHeader-title {
  color: grey;
  text-align: center;
  transition: width 0.8s ease;

  h3 {
  }

  // &.swiper-slide {
  //   width: fit-content !important;
  //   width: 300px;
  // }


  &--selected {
    color: $primary;
  }
}