// ========================
// TAGS
// ========================
$tag-spacing: 8px;
$tag-hover-colour: #c60050;

.Tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin: 0;
  padding: 0;
}

// ========================
// ELEMENTS
// ========================
.Tags-item {
  display: inline-block;

  &:last-of-type {
    button {
      margin-bottom: 16px;
    }
  }
}

.Tags-item button {
  @include font(14px);

  appearance: none;
  font-family: inherit;
  outline: none;
  border: none;
  background-color: transparent;

  padding-left: 0;
  margin-bottom: $tag-spacing;
}

.Tag-item-label {
  @extend %font-bold;
  color: $secondary;
  font-family: 'Gilroy Medium', system-ui, sans-serif;
  padding-bottom: rem(2px);
}

.Tag-item-label-close.SVG {
  width: $v;
  height: $h;
}

// ========================
// UI STATES
// ========================

.Tags-item.is-removable button {
  @include font(16px);

  &:first-of-type {
    padding-left: 0;
  }
  @media #{$desktop} {
    @include font(20px);
  }
}

.Tag-item-label.is-removable {
  text-decoration: $flamingo;
  text-decoration: underline;
  padding-bottom: 0px;
}
