// ========================
// IDEAS FACTORY TILES
// ========================
// @note: Used on the Ideas Factory blog layout
// ========================

.IdeasFactoryTile {
  border: solid 1px $border;
  background-color: $gentler-rhino;
  border-radius: 4px;
  margin-bottom: $v / 2;
  will-change: transform, box-shadow;

  @media #{$laptop} {
    margin-bottom: $v;
  }

  a {
    text-decoration: none;
  }
}

// ========================
// ELEMENTS
// ========================
.IdeasFactoryTile-content {
  // padding: $v / 1.5 $h / 1.5;
  padding-bottom: 0;

  .Author-name,
  .Author-role {
    color: $primary;
    line-height: 1.25;
  }
}

.IdeasFactoryTile-content-date-container {
  padding: $v / 1.5 $h / 1.5;
  padding-bottom: 0;
}

.IdeasFactoryTile-content-date {
  margin-top: 0;
  margin-bottom: 0;
  @include font(14px);

  line-height: 1; // Vertical white space around text is inconsistent at 1.5

  @media #{$widescreen} {
    // @include font(16px, 16px);
  }
}

.IdeasFactoryTile-content-readIcon {
  display: block;
  width: rem(24px);
  height: rem(24px);
  border-radius: rem(50px);
  background-image: url('/assets/images/interface/read.svg');
}

.IdeasFactoryTile-content-title {
  @extend %font-bold;
  display: inline-block;
  transition: color 0.3s ease-in-out;
  line-height: 1.25;
  padding: 8px $h / 1.5;
  margin: 0;
  @media #{$widescreen} {
    @include font(20px);
  }
}

.IdeasFactoryTile-content-tags-container {
  padding: 0 $h / 1.5;
}
// ========================
// MODIFIERS
// ========================
.IdeasFactoryTile--landscape {
  display: flex;
  align-items: center;

  .IdeasFactoryTile-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ImageWrapper {
    @include fit-media(16, 6);
  }
}

// ========================
// UI STATES
// ========================
.IdeasFactoryTile-content:hover .IdeasFactoryTile-content-title {
    color: $flamboyant-flamingo;
  }


.IdeasFactoryTile.is-read {
  .IdeasFactoryTile-content-date,
  .IdeasFactoryTile-content-title {
    color: rgba($primary, 0.6);
  }
}
