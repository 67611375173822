// ========================
// SKELETON GRID
// ========================
$gridline-weight: 2px;
$gridline-cell-height: 340px;
$gridline-cell-height-sm: 230px;
$gridline-fade-out: 200px;
// ========================

.SkeletonGrid {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  user-select: none;

  @media #{$tablet} {
    display: block;
  }
}

// ========================
// ELEMENTS
// ========================
.SkeletonGrid-row,
.SkeletonGrid-row-cell {
  position: relative;
}

// .SkeletonGrid-row:first-child {
//   .Grid-cell {
//     height: $gridline-cell-height;
//   }

//   &::before {
//     content: '';
//     display: block;
//     position: absolute;
//     height: $gridline-weight;
//     width: 100%;
//     top: 100%;
//     background: url('/assets/images/interface/grid-line-horizontal.svg') 0 0
//       repeat-x;
//     background-size: auto $gridline-weight;
//   }

//   // Turns off horizontal rule

//   .Article &,
//   .HighlightModule & {
//     &::before {
//       display: none;
//     }
//   }
// }

.SkeletonGrid-row:last-child {
  .Grid-cell {
    height: $gridline-cell-height * 2;

    &::before,
    &::after {
      box-shadow: inset 0 -200px 100px -100px white;
    }
  }
}

.SkeletonGrid-row-cell {
  // vertical lines
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: $gridline-weight;
    height: 100%;
    background: url('/assets/images/interface/grid-line-vertical.svg') 0 0
      repeat-y;
    background-size: $gridline-weight auto;
  }

  // left vertical line
  &::before {
    top: 0;
    left: -4px;

    @media #{$laptop} {
      left: 4px;
    }
  }

  // right vertical line
  &::after {
    top: 0;
    right: -4px;

    @media #{$laptop} {
      right: 4px;
    }
  }
}

// ========================
// MODIFIERS
// ========================
// used on the Ideas Factory Landing Page
.SkeletonGrid--landing {
  .SkeletonGrid-row:first-child {
    .Grid-cell {
      height: $gridline-cell-height-sm;
    }
  }
}

// ========================
// UI STATES
// ========================
// .SkeletonGrid.is-active {}
