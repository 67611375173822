// ============
// $SELECT BOX
// ============

// Usage:
// <div class="Select">
//   <select>
//     <option></option>
//   </select>
// </div>

.Select {
  position: relative;
  display: block;

  select {
    @extend %font-regular;
    color: $midnight;
    cursor: pointer;
    height: $v * 2.5;
    appearance: none;
    background: $white;
    border: 1px solid $midnight;
    border-radius: 0;
    box-shadow: none;
    padding-left: $h;
    padding-right: $v * 2.5; // affordance space
    outline: none;
    width: 100%;

    // Hide the default arrow in IE
    &::-ms-expand {
      display: none;
    }

    // Hide FF's focus outline (since we add our own focus state)
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $midnight;
    }

    // Revert the selected/focused state background color that IE adds
    &:focus::-ms-value {
      color: $midnight;
      background: $white;
    }

    &:focus {
      border-color: $form-element-focus-color;
    }

    &[aria-invalid="true"] {
      border-color: $error;
      color: $error;
    }
  }

  // Dropdown arrow ============
  &::before {
    @extend %icon;
    @extend .icon-chevron;
    @include font(20px, 40px);
    text-align: center;
    width: rem(40px);
    height: rem(40px);
    pointer-events: none;
    position: absolute;
    top: rem(10px);
    right: rem(10px);
  }

  // Error Styles ============
  &.has-error {
    &::before {
      color: $error;
    }
  }
}
