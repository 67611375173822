// =============
// IMAGE WRAPPER
// =============

.ImageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

// ========================
// ELEMENTS
// ========================

.ImageWrapper-img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ImageInfo {
  display: none;
  position: absolute;
  top: $v;
  left: $h;
  background-color: $white;
  box-shadow: 0 2px 4px 1px $grey-light;
  padding: $v / 2;
  text-align: left;
  color: $primary;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

// =============
// MODIFIER
// =============

.ImageWrapper--hasLoader {
  background-color: $gentle-rhino;
  animation: placeholder-pulse 1s both normal infinite ease-in-out;
  transition: background-color 0.2s linear;
}

.ImageWrapper--round {
  .ImageWrapper-img {
    border-radius: 50%;
  }
}

.ImageWrapper--16x9 {
  @include fit-media(16, 9);
}

.ImageWrapper--square {
  @include fit-media(1, 1);
}

.ImageWrapper--contain .ImageWrapper-img {
  background-size: contain !important;
}
// =============
// STATE
// =============

.ImageWrapper.is-ready {
  animation: none;
  background-color: transparent;

  .ImageWrapper-img {
    opacity: 1;
  }
}

.ImageWrapper:hover {
  .ImageInfo {
    display: block;
  }
}
