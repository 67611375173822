// ============
// Colours
// ============

// Brand ============
$midnight: #3d2051;
$cyan: #00dcd2;
$flamingo: #ff0068;
$flamboyant-flamingo: #df015b;
$rhino: #52575e;
$gentle-rhino: #eeeeef;
$gentler-rhino: #f9f9f9;
$charcoal: #121212;
$red: #ed042e;
$teal: #00dcd2;
$border-grey: #d8d8d8;
$sunny: #fdf15e;
$magic-grey: #3a3c3f;
$grape: #471dc3;
$tsunami-green: #182427;
$blue-chalk: #ead2fd;

// Brand Gradients ==========

$sky: linear-gradient(to bottom right, #ffffff, #00e8db);
$sunset: linear-gradient(to bottom right, #ff0068, #050058);

// Aliases ==========
$primary: $midnight;
$secondary: $flamingo;
$secondary-dark: $flamboyant-flamingo;

// Tones ============
$black: #000;
$off-black: $charcoal;
$grey-dark: $rhino;
$grey-med: #adadad;
$grey-light: #cecece;
$border: #ececec;
$off-white: $gentle-rhino;
$white: #fff;

// Text ============
$text: $primary;
$link: $secondary;
$active: $secondary-dark;
$highlight: $secondary;

// Messages ============
$error: $red;
$info: $red;
$success: $red;
$warning: $red;

// ============
// Z-indices
// ============
$z-0: 0;
$z-slider-controls: 10; // Also used by the VideoPlayer-play button
$z-capsule: 20;
$z-notification: 25;
$z-header: 30;
$z-site-banner: 40;
$z-work-with-us: 45;
$z-menu: 50;

// ============
// UI Elements
$header-height-sm: rem(80px);
$header-height-md: max(5rem, 7.638888888888889vw);
$banner-height: rem(90px);

// ============
// Form elements
// ============
$form-element-height: 40px; // button, select
$form-element-border-colour: rgba($black, 0.25);
$form-element-border-width: 2px;
$form-element-border-radius: 3px;
$form-element-focus-color: $teal;
$form-element-disabled-color: transparentize($grey-med, 0.5);
$form-element-padding: $h * 2;

// ===================
// Basic interactions
// ==================

$timing: 0.2s;
$timing--closeButton: 0.3s;
$timing--menuButton: 0.3s;
$timing--routes: 0.3s;

$easing: ease(in-out-expo);

p {
  color: $magic-grey;
}
