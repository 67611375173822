// ========================
// IDEAS FACTORY
// ========================
.IdeasFactory {
  position: relative;

  a {
    text-decoration: none;
  }
}

// ========================
// ELEMENTS
// ========================
.IdeasFactory-layout {
  position: relative;
  z-index: 1;
}

.IdeasFactory-header {
  padding-top: rem(56px);
  padding-bottom: rem(23px);

  @media #{$tablet} {
    display: flex;
    justify-content: space-between;
    padding-top: rem(64px);
    padding-bottom: rem(30px);
  }
  @media #{$laptop} {
    padding-top: rem(72px);
    padding-bottom: rem(40px);
  }
  @media #{$desktop} {
    padding-top: rem(80px);
    padding-bottom: rem(48px);
  }
}

.IdeasFactory-header-title {
  @media #{$tablet} {
    width: 50%;
  }
  @media #{$laptop} {
    width: 66.6666%;
  }
}

.IdeasFactory-header-cta {
  @media #{$tablet} {
    display: block;
    width: calc(33.333% - 16px);
    margin: 0 56px 0 72px; // Magic number - Container is offset to right by 8px
  }
  @media #{$laptop} {
    margin: 0;
  }
  @media #{$desktop} {
    width: calc(25% - 24px);
    padding-right: 16px; // Magic number - to have even space between the column
    padding-left: $h / 3;
  }

  .Link {
    @include font(16px);

    padding-top: rem(8px);
    height: auto;

    @media #{$widescreen} {
      @include font(20px);
    }
  }
}

.IdeasFactory-header-cta-description {
  @media #{$tablet} {
    @include font(14px);
    line-height: 1.3;
  }

  @media #{$laptop} {
    @include font(16px);
  }
  @media #{$widescreen} {
    @include font(20px);
    margin-top: 0;
  }
}

.IdeasFactory-filters {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $v;
}

.IdeasFactory-filters-heading {
  @include font(16px);
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 8px;

  @media #{$desktop} {
    @include font(20px);
  }
}

.IdeasFactoryModule-content {
  padding-top: rem(28px);
  padding-bottom: rem(28px);

  @media #{$laptop} {
    padding-top: rem(32px);
    padding-bottom: rem(32px);
  }

  @media #{$desktop} {
    padding-top: rem(48px);
    padding-bottom: rem(48px);
  }
}

.MasonryGrid {
  display: flex;
  width: auto;

  margin-left: calc(-0.5 * #{$grid-gutter-sm});
  margin-right: calc(-0.5 * #{$grid-gutter-sm});

  @media #{$laptop} {
    margin-left: calc(-0.5 * #{$grid-gutter-lg});
    margin-right: calc(-0.5 * #{$grid-gutter-lg});
  }
}

.MasonryGrid-column {
  background-clip: padding-box;

  padding-left: calc(0.5 * #{$grid-gutter-sm});
  padding-right: calc(0.5 * #{$grid-gutter-sm});

  @media #{$laptop} {
    padding-left: calc(0.5 * #{$grid-gutter-lg});
    padding-right: calc(0.5 * #{$grid-gutter-lg});
  }
}
