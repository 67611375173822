// ======================
// CASE STUDY AWARD
// ======================

$icon-size: rem(56px);

.Award {
  position: relative;
  width: rem(270px);
  padding-left: $icon-size;
  min-height: $icon-size;
  display: flex;
  align-items: center;

  @media #{$tablet} {
    margin-top: 0;
    width: rem(216px);
  }

  @media #{$laptop} {
    width: rem(212px);
  }

  @media #{$desktop} {
    width: rem(258px);
  }
}

// ========================
// ELEMENTS
// ========================

.Award-image {
  position: absolute;
  height: $icon-size;
  width: $icon-size;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.Award-blurb {
  margin-left: $h / 1.5;

  @media #{$laptop} {
    margin-left: $h;
  }
}

.Award-blurb-title {
  @extend %font-bold;
}
