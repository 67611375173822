// ============
// $FIT MEDIA CONTENT / MAINTAIN ASPECT RATIO
// ============

// e.g.
// <div class="Column">
//   <div class="ShowPanel">
//     <img src="http://lorempixel.com/400/300/nature/" alt="..." />
//   </div>
//   <div class="ShowPanel">
//     <iframe width="560" height="315" src="..."></iframe>
//   </div>
// </div>
//
// .ShowPanel {
//   @include fit-media(16, 9);
// }

// @NOTE: ImageWrapper has built in options for adding Square and 16x9 ratios

@mixin fit-media($width, $height) {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: ($height / $width) * 100%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
