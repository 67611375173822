.VideoControls {
  padding: 8px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity 0.3s ease;

  @media #{$tablet} {
    padding: 12px;
    justify-content: flex-end;
  }

  @media #{$laptop} {
    padding: 16px;
  }
}

@mixin pill {
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: rgba($midnight, 0.15);
  padding: 0 $h / 4;

  @media #{$tablet} {
    height: 40px;
    border-radius: 16px;
  }
}

.VideoControls-clickHandler {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.VideoControls-topControls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  @media #{$tablet} {
    display: none;
  }
}

.VideoControls-bar {
  @include pill();
  z-index: 1;

  width: 100%;
  touch-action: none;
}

.VideoControls-button {
  border: none;
  background: none;
  position: relative;
  margin: 0;
  height: 100%;
  padding: 0 $h / 3;
  line-height: 0;
  appearance: none;
  text-decoration: none;
  user-select: none;
  white-space: normal;
  cursor: pointer;

  // increase the tap area on small screens
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 200%;
    left: 0;
    top: -50%;

    @media #{$tablet} {
        content: none;
    }
  }


  &:focus:not(.focus-visible) {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }
}

.VideoControls-buttonIcon {
  height: 11px;
  width: 11px;

  @media #{$tablet} {
    height: 15px;
    width: 15px;
  }
}

.VideoControls-time {
  margin-right: $h / 4;
  font-family: $base-font-family;
  font-size: rem(12px);
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  line-height: 0;
  color: $white;

  @media #{$tablet} {
    margin-right: $h / 3;
    font-size: rem(14px);
  }

  @media #{$laptop} {
    margin-left: $h / 2;
    margin-right: $h / 2;
    font-size: rem(16px);
  }
}

// Variations

.VideoControls-button--pill {
  @include pill();
  padding: 0 $h / 2;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    left: -50%;
    top: -50%;
  }
}

.VideoControls--hidden {
  pointer-events: none;
  opacity: 0;
}
