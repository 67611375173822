// ============
// $PADDING
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

// Specify the proportional offset before an element.
// Intentional redundancy build into each set of unit classes.
// Supports: 2, 3, 4, 6, 12 section

@mixin offset-before($namespace: "") {
  // None ============
  .u-#{$namespace}beforeNone { margin-left: 0 !important; }
  // // Halves ============
  .u-#{$namespace}before1of2 { margin-left: #{(1 / 2) * 100%} !important; }
  // // Thirds ============
  // .u-#{$namespace}before1of3 { margin-left: #{(1 / 3) * 100%} !important; }
  .u-#{$namespace}before2of3 { margin-left: #{(2 / 3) * 100%} !important; }
  // // Quarters ============
  .u-#{$namespace}before1of4 { margin-left: #{(1 / 4) * 100%} !important; }
  // .u-#{$namespace}before2of4 { @extend .u-#{$namespace}before1of2 !optional; }
  // .u-#{$namespace}before3of4 { margin-left: #{(3 / 4) * 100%} !important; }
  // // Sixths ============
  .u-#{$namespace}before1of6 { margin-left: #{(1 / 6) * 100%} !important; }
  // .u-#{$namespace}before2of6 { @extend .u-#{$namespace}before1of3 !optional; }
  // .u-#{$namespace}before3of6 { @extend .u-#{$namespace}before1of2 !optional; }
  // .u-#{$namespace}before4of6 { @extend .u-#{$namespace}before2of3 !optional; }
  .u-#{$namespace}before5of6 { margin-left: #{(5 / 6) * 100%} !important; }
  // Twelfths ============
  .u-#{$namespace}before1of12 { margin-left: #{(1 / 12) * 100%} !important; }
  .u-#{$namespace}before2of12 { @extend .u-#{$namespace}before1of6 !optional; }
  // .u-#{$namespace}before3of12 { @extend .u-#{$namespace}before1of4 !optional; }
  // .u-#{$namespace}before4of12 { @extend .u-#{$namespace}before1of3 !optional; }
  .u-#{$namespace}before5of12 { margin-left: #{(5 / 12) * 100%} !important; }
  // .u-#{$namespace}before6of12 { @extend .u-#{$namespace}before1of2 !optional; }
  .u-#{$namespace}before7of12 { margin-left: #{(7 / 12) * 100%} !important; }
  // .u-#{$namespace}before8of12 { @extend .u-#{$namespace}before2of3 !optional; }
  // .u-#{$namespace}before9of12 { @extend .u-#{$namespace}before3of4 !optional; }
  // .u-#{$namespace}before10of12 { @extend .u-#{$namespace}before5of6 !optional; }
  // .u-#{$namespace}before11of12 { margin-left: #{(11 / 12) * 100%} !important; }
}

@include offset-before();
@media #{$mobile-only} { @include offset-before("sm-"); }
@media #{$tablet} { @include offset-before("md-"); }
@media #{$laptop} { @include offset-before("lg-"); }
@media #{$desktop} { @include offset-before("xl-"); }
@media #{$widescreen} { @include offset-before("xx-"); }
