// ============
// $MARGIN
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

@mixin margin($namespace: "") {
  // Margin top ============
  .u-#{$namespace}mT-0 { margin-top: 0 !important; }
  .u-#{$namespace}mT-xxs { margin-top: $v / 6 !important; }
  .u-#{$namespace}mT-xs { margin-top: $v / 3 !important; }
  .u-#{$namespace}mT-sm { margin-top: $v / 2 !important; }
  .u-#{$namespace}mT-md { margin-top: $v !important; }
  .u-#{$namespace}mT-lg { margin-top: $v * 2 !important; }
  .u-#{$namespace}mT-xl { margin-top: $v * 3 !important; }
  // Margin bottom ============
  .u-#{$namespace}mB-0 { margin-bottom: 0 !important; }
  .u-#{$namespace}mB-xxs { margin-bottom: $v / 6 !important; }
  .u-#{$namespace}mB-xs { margin-bottom: $v / 3 !important; }
  .u-#{$namespace}mB-sm { margin-bottom: $v / 2 !important; }
  .u-#{$namespace}mB-md { margin-bottom: $v !important; }
  .u-#{$namespace}mB-lg { margin-bottom: $v * 2 !important; }
  .u-#{$namespace}mB-xl { margin-bottom: $v * 3 !important; }
  // Margin left ============
  .u-#{$namespace}mL-0 { margin-left: 0 !important; }
  .u-#{$namespace}mL-xxs { margin-left: $h / 6 !important; }
  .u-#{$namespace}mL-xs { margin-left: $h / 3 !important; }
  .u-#{$namespace}mL-sm { margin-left: $h / 2 !important; }
  .u-#{$namespace}mL-md { margin-left: $h !important; }
  .u-#{$namespace}mL-lg { margin-left: $h * 2 !important; }
  .u-#{$namespace}mL-xl { margin-left: $h * 3 !important; }
  // Margin right ============
  .u-#{$namespace}mR-0 { margin-right: 0 !important; }
  .u-#{$namespace}mR-xxs { margin-right: $h / 6 !important; }
  .u-#{$namespace}mR-xs { margin-right: $h / 3 !important; }
  .u-#{$namespace}mR-sm { margin-right: $h / 2 !important; }
  .u-#{$namespace}mR-md { margin-right: $h !important; }
  .u-#{$namespace}mR-lg { margin-right: $h * 2 !important; }
  .u-#{$namespace}mR-xl { margin-right: $h * 3 !important; }
}

@include margin();
@media #{$mobile-only} { @include margin("sm-"); }
@media #{$tablet} { @include margin("md-"); }
@media #{$laptop} { @include margin("lg-"); }
@media #{$desktop} { @include margin("xl-"); }
// @media #{$widescreen} { @include margin("xx-"); }
