// ============
// CLOSE BUTTON
// ============
//
// This is the menu button that triggers the menu overlay.
// Sits in the header. Keyframes could do with some tidying.

// <button class="CloseButton">
//   <span class="CloseButton-inner">
//     <span class="CloseButton-line CloseButton-topLine">
//       &nbsp;
//     </span>
//     <span class="CloseButton-text">Close</span>
//     <span class="CloseButton-line CloseButton-bottomLine">
//       &nbsp;
//     </span>
//   </span>
// </button>

$btn-size:      $h * 2;
$line-width:    2px;
$line-leng-sm:  $h * 0.5;
$line-leng-md:  $h * 0.75;
$line-leng-lg:  $h;
$border-radius: $line-width;
$path-leng:     23.627;

.CloseButton {
  @extend %btn-reset;
  @extend %btn-padding;
  display: block;
  height: $btn-size;
  width: $btn-size;
  cursor: pointer;
  overflow: visible;
  box-sizing: border-box;
  position: relative;
  color: $white;
}

// ========================
// ELEMENTS
// ========================

.CloseButton-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.CloseButton-label {
  @extend %font-bold;
  @include font(16px);
  line-height: $btn-size;
  position: absolute;
  right: 0;
  top: 50%;
  transform: scale(1) translateY(-50%) translateX(-$line-leng-md);
  opacity: 0;
  will-change: color, opacity, transform;
  transform-origin: 100% 50%;
  transition: all ($timing--closeButton * 1.25) ease;
}

.CloseButton-paths {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .bottom-to-top,
  .top-to-bottom {
    stroke-dasharray: $path-leng;
    stroke-dashoffset: 0;
    stroke: $white;
    transform: translate(0, 0);
    will-change: transform, stroke, stroke-dashoffset;
  }

  .top-to-bottom {
    transition:
      stroke $timing--closeButton ease,
      stroke-dashoffset $timing--closeButton ease ($timing--closeButton / 3),
      transform $timing--closeButton ease ($timing--closeButton / 3);
  }

  .bottom-to-top {
    transition:
      stroke $timing--closeButton ease,
      stroke-dashoffset $timing--closeButton ease ($timing--closeButton / 1.5),
      transform ($timing--closeButton / 1.25) ease ($timing--closeButton / 1.5);
  }
}

.CloseButton-line {
  display: block;
  position: absolute;
  background: $white;
  height: $line-width;
  border-radius: $border-radius;
  right: 0;
  top: calc(50% - (#{$line-width} / 2));
  width: $line-leng-md;
  opacity: 0;
  transform: scaleX(0) translateX(24px);
  will-change: background, transform, opacity;
  transform-origin: 100% 50%;
  transition:
    background $timing--closeButton ease,
    transform $timing--closeButton ease ($timing--closeButton / 2),
    opacity $timing--closeButton ease ($timing--closeButton / 1.5);
}

.CloseButton:hover {
  .CloseButton-label {
    color: $secondary;
    opacity: 1;
    transform: scale(1) translateY(-50%) translateX(-$line-leng-md * 2.5);
  }

  .CloseButton-line {
    background: $secondary;
    opacity: 1;
    transform: scaleX(2) translateX(2px);
  }

  .CloseButton-paths .bottom-to-top {
    transform: translate(6px, -6px);
    stroke-dashoffset: $path-leng;
    stroke: $secondary;
  }

  .CloseButton-paths .top-to-bottom {
    stroke-dashoffset: $path-leng;
    transform: translate(6px, 6px);
    stroke: $secondary;
  }
}
