.ServiceCategory {
  margin-top: 112px;

  @media #{$tablet} {
    margin-top: 96px;
    margin-left: 48px;
    margin-right: 48px;
  }

  @media #{$laptop} {
    margin-top: 110px;
    margin-left: 72px;
    margin-right: 72px;
  }

  @media #{$desktop} {
    margin-top: 124px;
    margin-left: 80px;
    margin-right: 80px;
  }

  @media #{$widescreen} {
    margin-top: 120px;
    margin-left: 120px;
    margin-right: 120px;
  }
}
