// ===========================
// STATS - US PAGE - STA01-01a
// ===========================

// .Stats {}

// ========================
// ELEMENTS
// ========================

.Stats-list {
  flex-wrap: wrap;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;

    @media #{$tablet} {
      max-width: 128px;
      width: 100%;
    }

    @media #{$laptop} {
      max-width: 174px;
    }

    @media #{$desktop} {
      max-width: 211px;
    }
  }

  &-number {
    width: 100%;
    text-align: center;
    margin-top: $v / 1.5;

    &-count {
      line-height: 0.8;
      display: block;
    }
  }
}
