// ========================
// FeaturedCaseStudies
// ========================
// @note: Displayed on the home page
// ========================

.FeaturedCaseStudies {
  margin-bottom: $v;

  @media #{$tablet} {
    margin-bottom: $v * 0.75;
    overflow: initial;
  }
  @media #{$laptop} {
    margin-bottom: $v;
  }
  @media #{$desktop} {
    margin-bottom: $v * 1.25;
  }
  @media #{$widescreen} {
    margin-bottom: $v * 1.75;
  }
}

// ========================
// ELEMENTS
// ========================

.FeaturedCaseStudies-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.FeaturedCaseStudies-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.FeaturedCaseStudies-list-item {
  height: 0;
  width: 100%;
  padding-bottom: 200%;
  position: relative;
  margin-bottom: $v;
  display: block;
  transform: translateZ(-1000px);

  &:last-of-type {
    margin-bottom: 0;
  }

  @media #{$tablet} {
    width: 31.5%;
    height: 408px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  @media #{$laptop} {
    height: 546px;
  }
  @media #{$desktop} {
    height: 666px;
  }
  @media #{$widescreen} {
    height: 842px;
  }

  .SkeletonRect {
    height: 100%;
  }
}

.FeaturedCaseStudies-list-item-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media #{$tablet} {
    position: static;
  }
}

.FeaturedCaseStudies-list-item-container {
  height: 100%;

  @media #{$tablet} {
    position: relative;
  }
}

.FeaturedCaseStudies-list-item-background {
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s linear;
  will-change: opacity;
}

.FeaturedCaseStudies-list-item-content {
  position: absolute;
  top: $v * 1.25;
  left: $h;
  color: $white;
}

.FeaturedCaseStudies-list-item-content-title {
  @extend %h4;
  @extend .u-textMedium;
  margin: 0;
}

.FeaturedCaseStudies-list-item-content-subtitle {
  @extend %font-bold;
  @extend %h4;

  margin-top: $v / 2;
  margin-bottom: 0;
  // animation setup for hover
  opacity: 0;
  transform: matrix(1, 0, 0, 1, 0, 12);
  transition:
    transform 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.2s linear;
  will-change: transform, opacity;

  @media #{$mobile-only} {
    display: none;
  }
}

// ========================
// MODIFIERS
// ========================
// .--modifier {}

// ========================
// UI STATES
// ========================
.FeaturedCaseStudies-list-item:hover {
  .FeaturedCaseStudies-list-item-content-subtitle {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
