// =============================
// LINES TO GRID (ENERGY SECTOR)
// =============================

// .LinesToGrid {}

// ========================
// ELEMENTS
// ========================

.LinesToGrid-content {
  position: relative;
  overflow: hidden; // Needed for iOS
}

.LinesToGrid-left,
.LinesToGrid-right {
  position: absolute;
  z-index: 2;
}

.LinesToGrid-left {
  position: relative;

  @media #{$tablet} {
    position: absolute;
    bottom: $v * 1.5;
    left: 0;
  }

  @media #{$laptop} {
    bottom: $v * 2;
  }

  @media #{$desktop} {
    bottom: $v * 2.5;
  }
}

.LinesToGrid-right {
  background-color: $white;
  opacity: 0.9;
  top: 50vw;

  @media #{$tablet} {
    background-color: none;
    opacity: 1;
    top: rem(248px);
  }

  @media #{$laptop} {
    top: rem(324px);
  }

  @media #{$desktop} {
    top: rem(400px);
  }
}

.LinesToGrid-title {
  @extend %font-bold;
  color: $flamingo;
  margin-top: $v / 3;
  margin-bottom: $v / 3;

  @media #{$tablet} {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.LinesToGrid-graphic {
  @include aspect-ratio(50, 69);
  z-index: 1;
  width: 125%;
  transform: translateX(-15%);

  @media #{$tablet} {
    width: 100%;
    transform: none;
  }
}
