// ========================================
// CASE STUDY QUOTE:
// QTE-01-01a
// ========================================

.CaseStudy-quote {
  text-align: center;
}

// ========================
// ELEMENTS
// ========================

.CaseStudy-quote-container {
  position: relative;
}

.CaseStudy-quote-container-content {
  border-radius: 50%;
  margin: auto;
}

.CaseStudy-quote-container-logo {
  font-size: rem(55px);
  color: $flamingo;
  position: relative;
  z-index: 1;

  &--teal {
    color: $teal;
  }

  &--midnight {
    color: $midnight;
  }

  &--grape {
    color: $grape;
  }
}

.CaseStudy-quote-text {
  margin: {
    top: $v / 4;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

.CaseStudy-quote-citation {
  display: block;
  margin: {
    top:  $v * 0.75;
    right: auto;
    bottom: $v * 0.75;
    left: auto;
  }
}

.CaseStudy-quote-container-content + .CaseStudy-quote-container-logo {
  margin-top: rem(-50px);
}

// ========================
// MODIFIERS
// ========================

.CaseStudy-quote-container-content.is-round {
  @media #{$mobile-only} {
    max-width: rem(305px);
  }
}
