// ============
// HERO
// ============

.Hero {
  padding-top: $v;
  margin-bottom: rem(80px);

  @media #{$tablet} {
    padding-top: $v * 1.5;
  }
}


// ========================
// ELEMENTS
// ========================

.Hero-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.Hero-content-tagline {
  flex-shrink: 0;
}

.Hero-content-graphic {
  flex-shrink: 0;
  display: flex;
  margin-bottom: $v * 2;
  justify-content: center;
  line-height: 0;

  @media #{$tablet} {
    margin-bottom: 0;
    justify-content: flex-end;
  }

  // Explicitly setting SVG heights for IE
  svg {
    @media #{$tablet} { min-height: rem(330px); }
    @media #{$laptop} { min-height: rem(452px); }
    @media #{$desktop} { min-height: rem(580px); }
    @media #{$widescreen} { min-height: rem(632px); }
  }
}

.Hero-content-graphic--loading {
  @include aspect-ratio(1, 1);
}
