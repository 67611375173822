// ============
// SITE BANNER
// ============

.SiteBanner {
  display: none;
  position: fixed;
  left: 0;
  bottom: $v / 2;
  color: $white;
  text-align: center;
  border-radius: 0 8px 8px 0;
  z-index: $z-site-banner;
  opacity: 0.8;
  overflow: hidden;
  transition: opacity $timing linear;

  &:hover {
    opacity: 1;
  }
}

.SiteBanner--dev,
.SiteBanner--pre-prod {
  display: flex;
}

.SiteBanner.is-hidden {
  pointer-events: none;

  .SiteBanner-button {
    pointer-events: all;
  }
}

.SiteBanner--dev .SiteBanner-content {
  background-color: $cyan;
}

.SiteBanner--pre-prod .SiteBanner-content {
  background-color: $flamingo;
}

.SiteBanner.is-hidden .SiteBanner-content {
  margin-left: -100%;
  opacity: 0;
}

.SiteBanner-content {
  transition: margin-left 0.3s ease-in-out, opacity 0.3s ease-in-out;

  padding: {
    top: $v / 2;
    right: $h / 2;
    bottom: $v / 2;
    left: $h / 2;
  }

  label {
    cursor: pointer;
  }
}

.SiteBanner-button {
  background: darken($teal, 10%);
  color: $white;
  border: none;
  width: 18px;
  padding: 0;
  z-index: 1;
  position: relative;
  flex-shrink: 0;

  .SiteBanner--pre-prod & {
    background: darken($flamingo, 10%);
  }

  @media #{$tablet} {
    width: 36px;
  }
}

.SiteBanner-button-text {
  @extend %fineprint;
  display: block;
  transform: rotate(90deg);
  text-transform: uppercase;
  position: absolute;
  top: -0.5em;
  left: 2px;
  transform-origin: bottom left;

  @media #{$tablet} {
    left: 4px;
  }
}
