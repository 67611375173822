// ====================================
// ROUTER - Animations in src/index.jsx
// ====================================

// .Router {}


// ========================
// ELEMENTS
// ========================

// Base class
.Router-fade-enter {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.Router-fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  transition: $timing--routes linear opacity;
}

// Active state
.Router-fade-enter-active {
  transition: $timing--routes linear opacity;
  transition-delay: $timing--routes;
  opacity: 1;
  z-index: 1;
}

.Router-fade-exit-active {
  opacity: 0;
}

// Done state
.Router-fade-enter-done {
  opacity: 1;
}
