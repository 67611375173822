// =============================
// CASE STUDY IMAGE PANEL: PAN01-01a
// =============================

$image-width-tablet: (1 / 3) * 100%;

.CaseStudy-imagePanel-images {
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
  
  .ImageWrapper {
    width: 100%;
    margin-bottom: $v / 2;

    @media #{$tablet} {
      height: auto;
      width: $image-width-tablet;
      margin-bottom: 0;
    }
  }
}