// ============
// $ALERT
// ============

$alert-colors: (
  error:   $error,
  warning: $warning,
  info:    $info,
  success: $success
);

.Alert {
  @extend %font-medium !optional;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: flex-start;
  padding: {
    top: $v / 2;
    right: $h / 2;
    bottom: $v / 2;
    left: $h / 2;
  }

  span {
    display: block;
  }
}

// ========================
// ELEMENTS
// ========================

.Alert-icon {
  margin-right: $h / 2;
}

// ========================
// MODIFIERS
// ========================

@each $type, $color in $alert-colors {
  .Alert--#{$type} {
    border-color: darken(hsl(hue($color), saturation($color), 90%), 5%);
    background-color: hsl(hue($color), saturation($color), 90%);
    color: $color;
    box-shadow: 0 rem(1px) rem(10px) rem(-2px) transparentize($color, 0.2);
  }
}
