// ============
// HOMEPAGE HERO
// ============

.HomePageHero {
  pointer-events: none; // To allow clickable links below hero to be clicked on.

  width: 100%;
  position: relative;
  line-height: 0;
  overflow: hidden;
  margin-bottom: rem(56px);

  @media #{$tablet} {
    @include aspect-ratio(1600, 680);

    pointer-events: auto;

    // Prevent the hero requiring vertical scrolling on wide screens
    // Value may need to be tweaked
    max-height: 70vh;
  }
  @media #{$laptop} {
    margin-bottom: rem(72px);
  }
  @media #{$desktop} {
    margin-bottom: rem(88px);
  }
  @media #{$widescreen} {
    margin-bottom: rem(96px);
  }
}


// ========================
// ELEMENTS
// ========================

.HomePageHero-content {
  @media #{$tablet} {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  }

  .Container {
    height: 100%;
  }

  &.is-loading {
    top: 25%;

    @media #{$mobile-only} {
      padding-bottom: 250px;
    }
  }
}

.HomePageHero-titleContainer {
  position: relative;

  @media #{$tablet} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

.HomePageHero-title {
  max-width: $v * 10;
  margin-bottom: 0;
  position: absolute;

  transition: transform 1000ms cubic-bezier(0.81, 0, 0.39, 1);
  visibility: visible;

  &.is-current {
    transform: translate3d(0, 0, 0);
    transition: transform 1000ms cubic-bezier(0.81, 0, 0.39, 1) 100ms, opacity 1000ms cubic-bezier(0.81, 0, 0.39, 1) 300ms;
    opacity: 1;

    @media #{$mobile-only} {
      transition: transform 1000ms cubic-bezier(0.81, 0, 0.39, 1) 50ms, opacity 1000ms cubic-bezier(0.81, 0, 0.39, 1) 300ms;
    }
  }

  &.is-exiting {
    transform: translate3d(-100vw, 0, 0);
    transition: transform 1000ms cubic-bezier(0.91, 0, 0.39, 1), opacity 1000ms;
    opacity: 0;
  }

  &.is-queued {
    transform: translate3d(70vw, 0, 0);
    opacity: 0;
  }

  @media #{$tablet} {
    margin-top: 0;
    max-width: none;
    width: 33%;
    padding-bottom: 41px;
  }
  @media #{laptop} {
    padding-bottom: 46px;
  }
  @media #{$desktop} {
    padding-bottom: 56px;
  }
  @media #{$widescreen} {
    width: 28%;
    padding-bottom: 66px;
  }
}

.HomePageHero-title,
.HomePageHero-subtitle {
  text-shadow: 0 2px 20px rgba($white, 0.6);
}

.HomePageHero-subtitle {
  margin-top: 16px;
  margin-bottom: 0;
  position: absolute;
}

.HomePageHero-imageSlider {
  @media #{$mobile-only} {
    @include aspect-ratio(4, 5);

    padding-top: rem(425px);

    .HomePageHero-backgroundWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.HomePageHero-backgroundWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  @media #{$tablet} {
    position: absolute;
    top: 0;
    height: 100%;
  }
}

.HomePageHero-background {
  width: 100vw;
  height: 100%;
  flex-shrink: 0;
  transition: transform 1000ms cubic-bezier(0.81, 0, 0.39, 1);
  visibility: visible;

  &.is-current {
    transform: translate3d(0, 0, 0);
  }

  &.is-exiting {
    transform: translate3d(-100vw, 0, 0);
  }

  &.is-queued {
    transform: translate3d(100vw, 0, 0);
    visibility: hidden;
  }
}
