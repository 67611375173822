// ========================================
// CASE STUDY DIAGONAL:
// DIA01-01a, DIA01-01b
// ========================================

// .CaseStudy-diagonal {}

// ========================
// ELEMENTS
// ========================

.CaseStudy-diagonal-content {
  position: relative;
}

.CaseStudy-diagonal-caption {
  margin-bottom: $v;
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    margin-top: $v;
    position: absolute;
    top: 0;
  }

  @media #{$laptop} {
    margin-top: $v * 2;
    margin-bottom: $v * 2;
  }

  @media #{$desktop} {
    margin-top: $v * 2.5;
    margin-bottom: $v * 2.5;
  }
}

.CaseStudy-diagonal-background {
  width: 100%;
  max-width: rem(360px);
  min-height: rem(200px); // This number makes sure that there is a space between texts when an image hasn't loaded.
  margin-left: auto;
  margin-right: auto;

  @media #{$laptop} {
    max-width: none;
    min-height: rem(554px);
  }

  @media #{$tablet} {
    max-width: none;
    min-height: rem(415px);
  }

  @media #{$desktop} {
    min-height: rem(632px);
  }
}

// Explicitly setting SVG heights for IE
.CaseStudy-diagonal-background .Animator svg {
  display: block;
  height: 100%;
  min-height: rem(280px);

  @media #{$tablet} {
    min-height: rem(420px);
  }

  @media #{$laptop} {
    min-height: rem(529px);
  }
}

// ========================
// MODIFIERS
// ========================

.CaseStudy-diagonal-caption--right {
  right: 0;
  left: auto;
}

.CaseStudy-diagonal-caption--bottom {
  top: auto;
  bottom: 0;
}

.CaseStudy-diagonal-bigText {
  font-size: rem(130px);
  margin: 0;
  line-height: 0.8;
  @extend %font-bold;

  @media #{$laptop} {
    font-size: rem(220px);
  }
}
