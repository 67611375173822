// ========================
// FOOTER
// ========================

.Footer {
  padding-top: $v * 2;
  padding-bottom: $v;
  background: $midnight;
  color: $white;

  @media #{$tablet} {
    padding-top: $v * 2;
    padding-bottom: rem(50px);
  }

  @media #{$laptop} {
    padding-top: $v * 4;
  }
}

// ========================
// ELEMENTS
// ========================

.Footer-social {
  border-top: 1px solid #fcf9ff32;
  margin-bottom: $v * 0.5;

  @media #{$laptop} {
    margin-bottom: $v * 1;
  }

  .SocialLinks-item a,
  .SocialLinks-item button {
    color: $white;
  }
}

.Footer-content {
  margin-bottom: $v * 2;

  span[data-icon="new-alphero-logo"] {
    width: rem(150px);

    @media #{$tablet} {
      width: rem(200px);
    }
  }

  @media #{$tablet} {
    margin-bottom: 0;
  }
}

.Footer-tagline {
  flex-shrink: 0;
  margin-top: $v / 2;
  margin-bottom: 0;

  @media #{$tablet} {
    margin-top: 0;
  }
}

.Footer-button {
  width: 100%;
}

// === Office Locations
.Footer-offices-list {
  list-style: none;
  margin-top: $v;
  margin-bottom: $v * 2;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
    margin-top: $v * 2;
  }
}

.Footer-offices-list-item {
  address {
    @extend %small;
    font-style: normal;
  }

  a {
    @extend %small;
    text-decoration: none;
    color: $white !important;
    cursor: default;
  }

  &:not(:last-child) {
    margin-bottom: $v;

    @media #{$tablet} {
      margin-bottom: 0;
      margin-right: $h * 3;
    }
    @media #{$desktop} {
      margin-right: $h * 5;
    }
  }
}

.Footer-button {
  @extend .u-textNoWrap;
  min-width: 0 !important;

  .LinkArrow g {
    stroke: $midnight;
  }
}

.Footer-credit {
  display: flex;
  padding-top: rem(20px);
  align-items: center;
  justify-content: space-between;

  color: $blue-chalk;
  border-top: 1px solid #fcf9ff32;

  @media #{$tablet} {
    padding-top: rem(40px);
  }
}

.footer-privacy {
  color: $blue-chalk;
  text-decoration: none;
  border-bottom: 1px solid $flamingo;
  transition: border 0.25s ease;

  &:hover {
    border-bottom-color: transparent;
  }
}

img[data-icon="signature"] {
  width: rem(43px);
}

// =========
// MODIFIERS
// =========
